import { axiosInstance } from './index'
import apiUrls from './apiUrls'
import { applicationAxiosInstance } from './axiosInstance'
import { returnAxiosError } from '../helpers'

export const getTransactionAmount = async (body, guid): Promise<any> => {
  const { data } = await axiosInstance.post(`${apiUrls.getTransactionAmount}/${guid}`, body)
  return data
}

export const getFullTransactionAmount = async (body, guid): Promise<any> => {
  const { data } = await axiosInstance.post(`${apiUrls.getFullTransactionAmount}/${guid}`, body)
  return data
}

export const getTransactionById = async (transaction_reference: number): Promise<any> => {
  const { data } = await axiosInstance.get(`${apiUrls.getTransactionById}/${transaction_reference}`)
  return data
}

export const parseDocument = async ({ doc_data, type }: { doc_data: string, type: string }): Promise<any> => {
  const response = await applicationAxiosInstance.post(`${apiUrls.parseDocument}`, { doc_data, type })
  return response.data
}

export const verifyTransactionSMS = async (reference: number, code: number) => {
  const { data } = await axiosInstance.post(apiUrls.smsTransactionAuthorization, { reference, code })
  return data
}

export const verifyTransactionCard = async (reference: number, code: number) => {
  const { data } = await axiosInstance.post(apiUrls.cardCodeTransactionAuthorization, { reference, code })
  return data
}

export const validateCustomerCard = async (body) => {
  const { data } = await applicationAxiosInstance.post(apiUrls.validateCard, body)
  return data
}

export const trackTransaction = async (payload) => {
  try {
    const { data } = await applicationAxiosInstance.post(`${apiUrls.trackTransaction}`, payload)
    console.log('data', data)
    return data
  } catch (e) {
    console.log('error trackTransactionRoutine', returnAxiosError(e))
  }
}

export const sendClientInfo = async (reference, payload) => {
  try {
    const { data } = await applicationAxiosInstance.post(`${apiUrls.sendClientInfo}/${reference}`, payload)
    console.log('data', data)
    return data
  } catch (e) {
    console.log('error trackTransactionRoutine', returnAxiosError(e))
  }
}

export const changeCurrency = async (state_guid, body) => {
  const { data } = await applicationAxiosInstance.post(`${apiUrls.changeCurrency}/${state_guid}`, body)
  return data
}

export const setPendingStatusForTxn = async (payload) => {
  try {
    const { data } = await applicationAxiosInstance.post(`${apiUrls.setPendingStatusForTxn}`, payload)
    console.log('data', data)
    return data
  } catch (e) {
    console.log('error setPendingStatusForTxn', returnAxiosError(e))
    throw e
  }
}

