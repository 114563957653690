import React from 'react'
import styled from 'styled-components'

const LoaderContainer = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default LoaderContainer

const Container = styled.div`
   width: 100%;
   display: flex;
   height: 100vh;
   flex-direction: row;
   align-items: center;
   justify-content: center;
`
