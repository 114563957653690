import { axiosInstance } from './axiosInstance'
import apiUrls from './apiUrls'
import { returnAxiosError } from '../helpers'

export const getCurrencyList = async (session_guid): Promise<any> => {
  try {
    const { data } = await axiosInstance.get(`${apiUrls.getCurrenciesList}/${session_guid}`)
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}
