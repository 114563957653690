import React from 'react'
import { useSelector } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { COMPANY_LOGO_URL, LOGO_TYPES } from '../../constants'
import { RootState } from 'redux/reducers/rootReducer'

const Logo = (props) => {
  const { src,type = LOGO_TYPES.LOCATION } = props
  const { logo_url: location_logo_url } = useSelector((state: RootState) => state.session.options)
  const { logo_url: tenant_logo_url } = useSelector((state: RootState) => state.session.tenant)

  const getLogo = () => {
    if (src) return src
    const tenantLogo = tenant_logo_url || COMPANY_LOGO_URL
    // if (type === LOGO_TYPES.LOCATION) return location_logo_url || tenantLogo
    return tenantLogo
  }

  return (
    <Container>
      <Img src={getLogo()} alt={'logo'} />
    </Container>
  )
}

export default withTheme(Logo)

const Container = styled.div`
  text-align: center;
  padding: 48px 0 35px;
`
const Img = styled.img`
  width: 100%;
  max-width: 200px;
  height: 40px;
  object-fit: contain;
}
`
