export const languages = [
  {
    label: '/us.svg',
    value: 'en',
    fullName: 'english',
  },
  {
    label: '/es.svg',
    value: 'es',
    fullName: 'español',
  },
  {
    label: '/de.svg',
    value: 'de',
    fullName: 'deutsch',
  },
  {
    label: '/fr.svg',
    value: 'fr',
    fullName: 'français',
  },
]
