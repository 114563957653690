export enum CURRENCIES {
  BITCOIN = 'bitcoin',
  ETHEREUM = 'ethereum',
  LITECOIN = 'litecoin',
  DAI = 'dai',
}

export const availableCoins = {
  [CURRENCIES.BITCOIN]: {
    primaryColor: '#f7931a',
    secondaryColor: '#fc8a00',
    logo: 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg',
    shortName: 'BTC',
  },
  [CURRENCIES.ETHEREUM]: {
    primaryColor: '#565656',
    secondaryColor: '#000000',
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
    shortName: 'ETH',
  },
  [CURRENCIES.LITECOIN]: {
    primaryColor: '#345d9d',
    secondaryColor: '#004098',
    logo: 'https://cryptologos.cc/logos/litecoin-ltc-logo.svg',
    shortName: 'LTC',
  },
  [CURRENCIES.DAI]: {
    primaryColor: '#f5ac37',
    secondaryColor: '#d38400',
    logo: 'https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg',
    shortName: 'DAI',
  },
}

export enum CONNECT_WALLET_METHODS {
  METAMASK = 'METAMASK',
  TRUST_WALLET = 'TRUST_WALLET',
  DIRECT = 'DIRECT',
}

export const connectWalletMethods = [
  {
    label: 'MetaMask',
    value: CONNECT_WALLET_METHODS.METAMASK,
    logoSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png',
  },
  {
    label: 'Trust Wallet',
    value: CONNECT_WALLET_METHODS.TRUST_WALLET,
    logoSrc: 'https://trustwallet.com/assets/images/media/assets/TWT.png',
  },
]

export const connectionInstructions = [
  {
    label: 'MetaMask',
    link: 'https://metamask.zendesk.com/hc/en-us/articles/360015489331-How-to-import-an-account',
  },
  {
    label: 'Trust Wallet',
    link: 'https://community.trustwallet.com/t/how-to-import-a-wallet-via-private-key/844',
  },
]

