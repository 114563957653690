import { returnAxiosError } from '../helpers'
import { axiosInstance } from './index'
import { TRANSACTION_ENV } from '../constants'
import apiUrls from './apiUrls'
import axios from 'axios'

interface OTPResponse {
  result: boolean
  error_code: string
  error: string
}

interface SendCustomerOTPSMS {
  phone: string
  transaction_mode?: TRANSACTION_ENV
  country?: string
}

const { NEXT_PUBLIC_QUILTT_API_KEY } = process.env

export const checkIfCustomerPhoneIsVerified = async (phone: string): Promise<boolean> => {
  try {
    const { data } = await axiosInstance.post(apiUrls.checkVerifiedCustomerByPhone, { phone })
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const checkIfCustomerEmailIsVerified = async (email: string): Promise<boolean> => {
  try {
    const { data } = await axiosInstance.post(apiUrls.checkVerifiedCustomerByEmail, { email })
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const sentOTPSMS = async (phone: SendCustomerOTPSMS): Promise<boolean> => {
  try {
    const { data } = await axiosInstance.post(apiUrls.sendOTPSMSToCustomer, { phone })
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const checkOTPSMS = async (phone: string, code: string): Promise<boolean> => {
  try {
    const { data } = await axiosInstance.post(apiUrls.checkOTPSMS, { phone, code })
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

interface SendOTPEmail {
  email: string,
  tenant_id?: number
}

export const sentOTPEmail = async (data: SendOTPEmail): Promise<boolean> => {
  try {
    const { data: response } = await axiosInstance.post(apiUrls.sendOTPEmailToCustomer, data)
    return response
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

interface CheckOTPEmail extends SendOTPEmail{
  code: string
}

export const checkOTPEmail = async (body: CheckOTPEmail): Promise<boolean> => {
  try {
    const { data } = await axiosInstance.post(apiUrls.checkOTPEmail, body)
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const closeSession = async (guid: string): Promise<boolean> => {
  const header = {
    'Authorization': process.env.NEXT_PUBLIC_API_AUTH_TOKEN,
  }
  try {
    const { data } = await axiosInstance.post(`${apiUrls.closeSession}/${guid}`, {}, { headers: header } )
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const registerAlgoWallet = async (data) => {

  const header = {
    'authorization': `Bearer ${data.token}`,
  }

  const body = {
    currency: 'USD',
    email: data.email,
    username: data.username,
    provider: 'email',
  }

  try {
    return await axios.post(`${apiUrls.algomarket}/accounts`, body, { headers: header })
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const loginAlgoWallet = async (data) => {

  const header = {
    'authorization': `Bearer ${data.token}`,
  }

  const body = {
    email: data.email,
    provider: 'email',
  }

  try {
    return await axios.post(`${apiUrls.algomarket}/accounts`, body, { headers: header })
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const loginAlgoPaymentForm = async (data) => {

  const body = {
    email: data.email,
    provider: 'email',
  }

  try {
    const res = await axios.post(`${apiUrls.algomarket}/accounts/payment-form`, body)
    const { market_token } = res?.data
    if (market_token) localStorage.setItem('market_token', market_token)
    return res
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const getAllNftTemplates = async () => {

  try {
    return await axios.get(`${apiUrls.algomarket}/packs/search?type=free`)
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const sendNftToCustomer = async (data: { token: string, templateId: string }) => {

  const header = {
    'authorization': `Bearer ${data.token}`,
  }

  const body = {
    templateId: data.templateId,
  }

  try {
    return await axios.post(`${apiUrls.algomarket}/packs/claim/free`, body, { headers: header })
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const getQuilttInfo = async (data) => {

  const credentials = `${data.quiltt_profile_id}:${NEXT_PUBLIC_QUILTT_API_KEY}`

  const encoded = btoa(credentials)

  const header = {
    'Authorization': `Basic ${encoded}`,
    'Content-Type': 'application/json',
  }

  const graphqlQuery = `{
    profile {
      address {
        countryCode
        city
        line2
        line1
        postalCode
        state
      }
      dateOfBirth
      email
      id
      metadata
      name
      phone
      uuid
    }
    connections(filter: {status: SYNCED}) {
      id
      status
      institution {
        id
        name
        logo {
          url
        }
      }
    }
    accounts {
      id
      name
      type
      provider
      balance {
        current
        available
        limit
      }
      institution {
      name
      }
      remoteData {
        mx {
          account {
            response {
              accountNumber
              routingNumber
            }
          }
        }
      }
    }
    transactions {
      nodes {
        id
        date
        description
        amount
      }
    }
  }`

  try {
    const { data } =  await axios.post(`${apiUrls.quiltt}/v1/graphql`, { query: graphqlQuery }, { headers: header })
    console.log(7777, data?.data)
    return data?.data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const getAccountNumbers = async (accountId: string) => {
  const header = {
    'Authorization': `Bearer ${NEXT_PUBLIC_QUILTT_API_KEY}`,
    'Content-Type': 'application/json',
  }

  try {
    const { data } =  await axios.get(`${apiUrls.quiltt}/v1/accounts/${accountId}/ach_numbers`, { headers: header })
    return data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}
