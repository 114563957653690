import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { setLanguage } from '../../redux/actions/uiActions'
import { Typography } from '@material-ui/core'
import { languages } from '../../constants/localization'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { logEvent } from '../../services/sentryService'
import { RootState } from '../../redux/reducers/rootReducer'

const LanguageSelector = () => {
  const { localization } = useSelector((state: RootState) => state.ui)
  const matches = useMediaQuery('(min-width:935px)')
  const dispatch = useDispatch()
  const classes = useStyles()

  const onChange = async (e) => {
    logEvent(`Changed language to ${e.target.value}.`)
    await dispatch(setLanguage(e.target.value))
  }

  return (
    <Select
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
      }}
      value={localization}
      onChange={onChange}
      disableUnderline
      MenuProps={{ classes: { paper: classes.selectMenu }}}
    >
      {languages.map(({ value, label, fullName }, index) => {
        return (
          <MenuItem key={index} value={value} className={classes.item}>
            <img className={classes.image} src={label} />
            {matches ? <Typography className={classes.langTitle}>{fullName}</Typography> : ''}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default LanguageSelector

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  select: {
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    color: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    padding: '8px 16px 8px 20px',
    borderRadius: 8,
    '& p': {
      textTransform: 'capitalize',
    },
  },
  icon: {
    color: '#fff',
    marginRight: 16,
  },
  image: {
    width: 22,
    boxSizing: 'border-box',
    marginRight: 16,
  },
  item: {
    textTransform: 'capitalize',
  },
  langTitle: {
    marginRight:27,
  },
  selectMenu: {
    color: '#000',
    background: '#fff',
  },
}))
