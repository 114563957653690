export const CHAINS = {
  '0x1': {
    currency: 'eth',
  },
  '0x38': {
    currency: 'bnb',
  },
}

export enum CurrenciesTypes {
  AKROMA = 'AKROMA',
  ATH = 'ATH',
  BSC = 'BSC',
  BSC_TEST = 'BSC_TEST',
  CLO = 'CLO',
  EGEM = 'EGEM',
  ELLA = 'ELLA',
  ERE = 'ERE',
  ESN = 'ESN',
  ETC = 'ETC',
  ETH = 'ETH',
  ETHO = 'ETHO',
  EWT = 'EWT',
  EXP = 'EXP',
  GO = 'GO',
  GOERLI = 'GOERLI',
  IOLITE = 'IOLITE',
  KOV = 'KOV',
  MATIC = 'MATIC',
  MINTME = 'MINTME',
  MIX = 'MIX',
  MOONBEAM = 'MOONBEAM',
  MOONRIVER = 'MOONRIVER',
  MUSIC = 'MUSIC',
  PIRL = 'PIRL',
  POA = 'POA',
  REOSC = 'REOSC',
  RIN = 'RIN',
  ROP = 'ROP',
  RSK = 'RSK',
  RSK_TEST = 'RSK-TEST',
  TOMO = 'TOMO',
  TT = 'TT',
  UBQ = 'UBQ',
  WTC = 'WTC',
  ETHC = 'ETHC',
  BTC = 'BTC',
  BCH = 'BCH',
  BTG = 'BTG',
  BSV = 'BSV',
  LTC = 'LTC',
  ADA = 'ADA',
  DOGE = 'DOGE',
  XMR = 'XMR',
  DOT = 'DOT',
}

export const networks = [
  {
    type: CurrenciesTypes.ETH,
    'name': 'eth-mainnet',
    'chain_id': '1',
    'is_testnet': false,
    'db_schema_name': 'chain_eth_mainnet',
    'label': 'Ethereum Mainnet',
    'logo_url': 'https://www.covalenthq.com/static/images/icons/display-icons/ethereum-eth-logo.png',
  },
  {
    type: CurrenciesTypes.ROP,
    'name': 'rop-testnet',
    'chain_id': '3',
    'is_testnet': true,
    'db_schema_name': 'chain_eth_testnet',
    'label': 'Ropsten Testnet',
    'logo_url': 'https://www.covalenthq.com/static/images/icons/display-icons/ethereum-eth-logo.png',
  },
  {
    type: CurrenciesTypes.KOV,
    'name': 'eth-kovan',
    'chain_id': '42',
    'is_testnet': true,
    'db_schema_name': 'chain_eth_kovan',
    'label': 'Ethereum Testnet Kovan',
    'logo_url': 'https://www.covalenthq.com/static/images/icons/display-icons/ethereum-eth-logo.png',
  },
  {
    type: CurrenciesTypes.BSC,
    'name': 'bsc-mainnet',
    'chain_id': '56',
    'is_testnet': false,
    'db_schema_name': 'chain_bsc_mainnet',
    'label': 'Binance Smart Chain',
    'logo_url': 'https://www.covalenthq.com/static/images/icons/display-icons/binance-coin-bnb-logo.png',
  },
  {
    type: CurrenciesTypes.BSC_TEST,
    'name': 'bsc-testnet',
    'chain_id': '97',
    'is_testnet': true,
    'db_schema_name': 'chain_bsc_testnet',
    'label': 'Binance Smart Chain Testnet',
    'logo_url': 'https://www.covalenthq.com/static/images/icons/display-icons/binance-coin-bnb-logo.png',
  },
]
