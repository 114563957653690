
export default {
  //Transaction
  getTransactionById: 'transaction-process/r',
  getTransactionAmount: 'transaction-process/get-amount/guid',
  getFullTransactionAmount: 'transaction-process/get-full-amount/guid',
  getCurrenciesList: 'transaction-process/get-transaction-currency',
  getTransactionsList: 'app/user-transactions',
  approveOrder: 'app/approve-order',
  rejectOrder: 'app/reject-order',
  getUserBalance: 'app/user-balance',

  //OTP
  smsTransactionAuthorization: 'transaction-process/r/sms-verification',
  cardCodeTransactionAuthorization: 'transaction-process/r/card-verification',
  checkVerifiedCustomerByPhone: 'transaction-process/customer/verification/phone/check',
  checkVerifiedCustomerByEmail: 'transaction-process/customer/verification/email/check',
  sendOTPSMSToCustomer: 'transaction-process/customer/otp/sms',
  checkOTPSMS: 'transaction-process/customer/otp/sms/check',
  sendOTPEmailToCustomer: 'transaction-process/customer/otp/email',
  checkOTPEmail: 'transaction-process/customer/otp/email/check',
  setPendingStatusForTxn: 'transaction-process/quiltt/status/pending',



  //Session
  startApp: 'app/start',
  goNext: 'app/next',
  refreshToken: 'app/refresh-token',
  logout: 'app/logout',
  goBack: 'app/back',
  trackTransaction: 'app/transaction/track',
  keepOrderSession: 'order/session/keep-alive',
  closeSession: 'order/session/close',
  sendClientInfo: 'app/transaction/client-info',

  //Helpers
  parseDocument: 'app/process-document',
  getReceiptBase64: 'receipt/generate-receipt',
  validateCard: 'app/validate-card',
  changeCurrency: 'app/reservation',

  algomarket: 'https://api.znftmarket.com',
  quiltt: 'https://api.quiltt.io',
}
