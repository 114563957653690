import {
  SET_LANGUAGE,
  SET_UI_THEME,
  HANDLE_IS_CURRENCY_LOADING,
  HANDLE_SNACK_BAR,
  HANDLE_OPT_EMAIL_MODAL,
  HANDLE_OPT_SMS_MODAL,
  HANDLE_EXPIRED_SESSION_POPUP,
  HANDLE_ROUTE_CHANGE,
  HANDLE_BUY_CRYPTO_MODAL,
  HANDLE_WALLET_INFO_MODAL,
  HANDLE_PAYMENT_METHOD_SELECTION_MODAL,
  HANDLE_RELOAD_MODAL,
  HANDLE_YLOAD_MODAL, HANDLE_AGREE_PAYMENT_MODAL,
} from '../actionTypes/ui'
import { theme } from '../../lib/styling'

interface UIInitialState {
  ui_theme: theme
  localization: string
  snackBar: {
    message: string
    opened: boolean
    duration: number
    severity: 'error' | 'success'
  }
  isOTPEmailModalShowing: boolean,
  isOTPSMSModalShowing: boolean,
  isBuyCryptoModalShowing: boolean,
  isPaymentMethodSelectionModalShowing: boolean,
  isWalletInfoModalShowing: boolean,
  openedExpiredSessionPopup: boolean,
  isRouteChanging: boolean,
  isCurrencyLoading: boolean,
  isReloadModal: {
    isOpen: boolean,
    amount: number,
  },
  isYloadModal: boolean,
  isAgreePaymentModal: boolean,
}

const initialState: UIInitialState = {
  ui_theme: theme,
  localization: 'en',
  snackBar: {
    message: '',
    opened: false,
    duration: 3000,
    severity: 'error',
  },
  isOTPEmailModalShowing: false,
  isOTPSMSModalShowing: false,
  isBuyCryptoModalShowing: false,
  isPaymentMethodSelectionModalShowing: false,
  isWalletInfoModalShowing: false,
  openedExpiredSessionPopup: false,
  isRouteChanging: false,
  isCurrencyLoading: false,
  isReloadModal: {
    isOpen: false,
    amount: 0,
  },
  isYloadModal: false,
  isAgreePaymentModal: false,
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SET_UI_THEME:
      return {
        ...state,
        ui_theme: action.payload,
      }
    case SET_LANGUAGE:
      return {
        ...state,
        localization: action.payload,
      }
    case HANDLE_SNACK_BAR:
      return {
        ...state,
        snackBar: {
          message: action.payload.message,
          opened: action.payload.opened,
          duration: action.payload.duration,
          severity: action.payload.severity,
        },
      }
    case HANDLE_OPT_EMAIL_MODAL: {
      return {
        ...state,
        isOTPEmailModalShowing: action.payload,
      }
    }
    case HANDLE_BUY_CRYPTO_MODAL: {
      return {
        ...state,
        isBuyCryptoModalShowing: action.payload,
      }
    }
    case HANDLE_PAYMENT_METHOD_SELECTION_MODAL: {
      return {
        ...state,
        isPaymentMethodSelectionModalShowing: action.payload,
      }
    }
    case HANDLE_WALLET_INFO_MODAL: {
      return {
        ...state,
        isWalletInfoModalShowing: action.payload,
      }
    }
    case HANDLE_OPT_SMS_MODAL: {
      return {
        ...state,
        isOTPSMSModalShowing: action.payload,
      }
    }
    case HANDLE_EXPIRED_SESSION_POPUP: {
      return {
        ...state,
        openedExpiredSessionPopup: action.payload,
      }
    }
    case HANDLE_ROUTE_CHANGE: {
      return {
        ...state,
        isRouteChanging: action.payload,
      }
    }
    case HANDLE_IS_CURRENCY_LOADING: {
      return {
        ...state,
        isCurrencyLoading: action.payload,
      }
    }
    case HANDLE_RELOAD_MODAL: {
      return {
        ...state,
        isReloadModal: {
          isOpen: action.payload.isOpen,
          amount: action.payload.amount || 0,
        },
      }
    }
    case HANDLE_YLOAD_MODAL: {
      return {
        ...state,
        isYloadModal: action.payload,
      }
    }
    case HANDLE_AGREE_PAYMENT_MODAL: {
      return {
        ...state,
        isAgreePaymentModal: action.payload,
      }
    }
    default:
      return { ...state }
  }
}
