import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import rootReducer from './reducers/rootReducer'
import { getSentryReduxEnhancer } from '../services/sentryService'

declare const window: Window &
    typeof globalThis & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
}

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      }) : compose

const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk),
  // getSentryReduxEnhancer(),
  // other store enhancers if any
)

const store = createStore(rootReducer, enhancer)

export default store
