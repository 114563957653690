import * as Sentry from '@sentry/react'


export const logEvent = (message: string, data?: any) => {
  if (!message) return

  // const transaction = Sentry.startTransaction({
  //   name: message,
  // })
  //
  // if (data) {
  //   if (typeof data === 'object' && !Array.isArray(data)) {
  //     Object.keys(data).forEach(key => transaction.setData(key, data[key]))
  //   } else {
  //     transaction.setData('data', data)
  //   }
  // }
  //
  // transaction.finish()
}

export const logException = (msg, name?) => {
  const error = new Error(msg)
  if (name) error.name = name
  // Sentry.captureException(error)
}

export const getSentryReduxEnhancer = () => {
  return Sentry.createReduxEnhancer({
    stateTransformer: state => {
      return  {
        ...state,
        transaction: null,
        terminal: {
          ...state.terminal,
          rawData: null,
        },
      }
    },
    configureScopeWithState: (scope, state) => {
      const { session_guid, session_state_guid, data } = state.session

      const name = data?.first_name && data?.last_name && `${data?.first_name} ${data?.last_name}`
      if (scope?._tags?.session_guid !== session_guid) {
        scope.setTag('session_guid', session_guid)
      }

      if (scope?._tags?.session_state_guid !== session_state_guid) {
        scope.setTag('session_state_guid', session_state_guid)
      }

      const sentryUser = scope.getUser()

      if (sentryUser.name !== name || sentryUser.email !== data?.email)
        scope.setUser({
          name: name || '',
          email: data?.email || '',
        })
    },
    actionTransformer: action => {
      if (action.type === 'SET_TRANSACTION_CARD') {
        return null
      }
      if (action.type === 'SET_RAW_CARD_NUMBER') {
        return null
      }
    },
  })
}
