import { AppContext, AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalStyles } from '../lib/styling'
import cookie from 'cookie'
import Loader from '../ui/atoms/loader/loader'
import { IncomingMessage } from 'http'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store from '../redux/store'
import withRedux from 'next-redux-wrapper'
import 'react-phone-input-2/lib/bootstrap.css'
import '../styles/styles.css'
import { handleRouteChange, setLanguage } from '../redux/actions/uiActions'
import { languages } from '../constants/localization'
import CustomSnackBar from 'ui/mui-components/CustomSnackbar'
import { getSession, goBack, processToken } from '../redux/actions/sessionActions'
import { returnAxiosError } from '../helpers'
import Unavailable from '../features/unavailable'
import { useRouter } from 'next/router'
import { selectNavigator } from '../redux/selectors/session'
import { RootState } from '../redux/reducers/rootReducer'
import LoaderContainer from '../components/LoaderContainer'
import * as Sentry from '@sentry/nextjs'
import { DEVICE_TYPES } from '../constants'
import Listeners from '../components/Listeners/Listeners'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import ExpiredSessionPopup from '../ui/mui-components/ExpiredSessionPopup'
import { useCheckToken } from 'helpers/hooks'
import { createTheme } from '@material-ui/core'

function MyApp({ Component, pageProps, query }: AppProps) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const { ui_theme, localization, openedExpiredSessionPopup } = useSelector((state: RootState) => state.ui)
  const { session_guid, data, device_type, payment_processor, options } = useSelector((state: RootState) => state.session)
  const router = useRouter()
  const { paths, step } = useSelector(selectNavigator)
  useCheckToken()
  const setTokens = () => {
    if (query.auth_token) localStorage.setItem('auth_token', query.auth_token)
    if (query.refresh_token) localStorage.setItem('refresh_token', query.refresh_token)
    if (query.reference) localStorage.setItem('reference', query.reference)
  }
  useEffect(() => {
    setTokens()
    getOrderSession()
    initialLanguage()
    router.events.on('routeChangeComplete', handleRoute)
    return () => {
      router.events.off('routeChangeComplete', handleRoute)
    }
  }, [])

  const handleRoute = (err, url) => {
    if (err.cancelled) {
      console.log(`Route to ${url} was cancelled!`)
    }
    dispatch(handleRouteChange(false))
  }

  useEffect(() => {
    if (step && !router.pathname.includes(step) && !router.pathname.includes('embed')) {
      router.replace(paths[step]())
    }
  }, [step])

  useEffect(() => {
    if (step && session_guid && !router.pathname.includes('embed')) {
      router.replace(paths[step]())
    }
  }, [session_guid])

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)

    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  async function initialLanguage() {
    const lang = languages.find((elem) => {
      return navigator.language.match(elem.value)
    })

    if (lang) await dispatch(setLanguage(lang.value))
  }

  const getDialogProps = () => {
    return {
      user: {
        email: data.email,
        name: data.first_name && data.last_name && `${data.first_name} ${data.last_name}`,
      },
      lang: localization,
    }
  }

  const getOrderSession = async () => {
    try {
      await dispatch(processToken())
      const sessionGuid = router.query?.sessionGuid as string
      const sessionStateGuid = router.query?.sessionStateGuid as string
      if (!session_guid && sessionGuid) {
        await dispatch(getSession(sessionGuid, sessionStateGuid))
      }
    } catch (e) {
      setError(returnAxiosError(e))
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }


  const renderMain = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )
    }

    if (error) {
      return <Unavailable />
    }

    return (
      <Component {...pageProps} />
    )
  }

  return (
    <MuiThemeProvider theme={createTheme(ui_theme)}>
      <ThemeProvider theme={ui_theme}>
        <GlobalStyles />
        <Sentry.ErrorBoundary
          fallback={<Unavailable />}
          showDialog={device_type === DEVICE_TYPES.WEB}
          dialogOptions={getDialogProps()}>
          <Provider store={store}>
            {openedExpiredSessionPopup && <ExpiredSessionPopup/>}
            {renderMain()}
            <CustomSnackBar />
            <Listeners />
          </Provider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

function parseCookies(req?: IncomingMessage) {
  if (!req || !req.headers) {
    return {}
  }

  return cookie.parse(req.headers.cookie || '')
}

MyApp.getInitialProps = async (context: AppContext) => {
  // Extract cookies from AppContext
  return {
    query: context.router.query,
    cookies: parseCookies(context?.ctx?.req),
  }
}

const makeStore = () => store

export default withRedux(makeStore)(MyApp)
