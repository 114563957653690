import { SET_SESSION_DATA, RESET_FORM } from '../actionTypes/session'
import api from '../../api'
import {
  handleOTPEmailModal,
  handleOTPSMSModal, handleRouteChange, manageExpiredSessionPopup,
  manageSnackBar,
  updateUITheme,
} from './uiActions'
import {
  createSimpleResp,
  formatPhone,
  getLocalizedString,
  returnAxiosError,
  closeTerminalSession,
  sleep,
} from '../../helpers'
import { DEVICE_TYPES, SESSION_TYPE, STEPS } from '../../constants'
import { Android } from '@material-ui/icons'
import { logout, refreshToken } from '../../api/orderSession'
import * as jwt from 'jsonwebtoken'

export const getSession = (sessionGuid: string, sessionStateGuid?: string) => async (dispatch, getState) => {
  const { openedExpiredSessionPopup } = getState().ui
  function createResult({
    session_guid = null,
    state_guid = null,
    order_id = null,
    is_available = null,
    step = null,
  }) {
    return {
      session_guid,
      state_guid,
      order_id: order_id,
      is_available: is_available,
      step: step,
    }
  }

  if (!api.startApp) {
    await window.location.reload()
    await sleep(2000)
  }

  let session: any = null

  try {
    session = await api.startApp(sessionGuid, sessionStateGuid)
  } catch(e) {
    await dispatch(manageSnackBar({
      message: returnAxiosError(e),
      opened: true,
    }))
  }

  if (!session) return createResult({ is_available: false })

  const {
    data,
    device_type,
    options,
    order_id,
    session_guid,
    session_state_guid,
    transaction_mode,
    transaction_reference,
    payment_processor,
    session_amount,
    session_tip,
    step,
    tenant,
    active,
    session_type,
    extraPP,
  } = session

  if (options.home_url) localStorage.setItem('home_url', options.home_url)

  await dispatch(setSession({
    active,
    session_guid,
    session_state_guid,
    options,
    data,
    transaction_mode,
    transaction_reference,
    order_id,
    device_type,
    session_amount,
    session_tip,
    step,
    tenant,
    payment_processor,
    extraPP,
    session_type,
    currencies: data.currencies,
    default_currency_guid: data.default_currency_guid,
    pre_transaction_reference: data.pre_transaction_reference,
    transaction_wallet: data.transaction_wallet,
    crypto_amount: data.crypto_amount,
    reservation_exp_date: data.reservation_exp_date,
    checkout_iframe: data.checkout_iframe,
  }))

  await dispatch(updateUITheme(tenant))
  if (openedExpiredSessionPopup) {
    dispatch(manageExpiredSessionPopup(false))
  }

  return createResult({
    session_guid,
    state_guid: session_state_guid,
    order_id,
    is_available: true,
    step: data.step,
  })
}

export const checkPhoneVerification = (phone: string) => async (dispatch, getState) => {
  const { enable_otp_sms } = await getState().session.options
  if (!enable_otp_sms) return createSimpleResp(true)

  try {
    if (await api.checkIfCustomerPhoneIsVerified(formatPhone(phone))) {
      return createSimpleResp(true)
    }

    const { error } = await dispatch(sentOTPSMS(formatPhone(phone)))
    if (error) return createSimpleResp(false, error)

    await dispatch(handleOTPSMSModal(true))
    return createSimpleResp(false)
  } catch (e) {
    return createSimpleResp(false, e.message)
  }
}

export const checkEmailVerification = (email: string) => async (dispatch, getState) => {
  const { enable_otp_email, skip_email_request } = await getState().session.options
  if (!enable_otp_email || skip_email_request) return createSimpleResp(true)

  try {
    if (await api.checkIfCustomerEmailIsVerified(email)){
      return createSimpleResp(true)
    }

    const { error } = await dispatch(sentOTPEmail(email))
    if (error) return createSimpleResp(false, error)

    await dispatch(handleOTPEmailModal(true))
    return createSimpleResp(false)
  } catch (e) {
    return createSimpleResp(false, e.message)
  }
}

export const logoutCustomer = () => async (dispatch, getState) => {
  const { session_state_guid: state_guid } = await getState().session
  
  const result = await api.logout(state_guid)
  if (result.success) {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('refresh_token')
    await dispatch(setSession({
      step: result.step,
    }))
  }
}

export const goNext = (params?: { sessionState?: any, predicatedStep?: STEPS }) => async (dispatch, getState) => {
  const { predicatedStep, sessionState } = params || {}
  const { session_state_guid: state_guid, session_guid: guid } = await getState().session

  if (!state_guid) return null
  await dispatch(handleRouteChange(true))

  let session: any = null

  try {
    session = await api.goNext(state_guid, guid, sessionState, predicatedStep)
  } catch(e) {
    await dispatch(manageSnackBar({
      message: returnAxiosError(e),
      opened: true,
    }))
  }

  if (!session) return await dispatch(handleRouteChange(false))

  if (session.step === getState().session.step) {
    await dispatch(handleRouteChange(false))
  }

  if (session.errors.length) {
    const message = session.errors.map(item => item.error).join('\n')
    await dispatch(manageSnackBar({
      message,
      opened: true,
    }))
  }

  if (session?.auth_token && session?.refresh_token) {
    localStorage.setItem('auth_token', session?.auth_token)
    localStorage.setItem('refresh_token', session?.refresh_token)
  }

  const {
    data,
    device_type,
    options,
    order_id,
    session_guid,
    session_state_guid,
    transaction_mode,
    transaction_reference,
    session_amount,
    session_tip,
    session_type,
    step,
    tenant,
    active,
  } = session

  if (!active && device_type === DEVICE_TYPES.TERMINAL && session_type !== SESSION_TYPE.MANUAL){
    closeTerminalSession()
  }

  await dispatch(setSession({
    active,
    session_guid,
    session_state_guid,
    options,
    data,
    transaction_mode,
    transaction_reference: transaction_reference || null,
    order_id,
    device_type,
    session_amount,
    session_tip,
    step,
    tenant,
    currencies: data.currencies,
    default_currency_guid: data.default_currency_guid,
    pre_transaction_reference: data.pre_transaction_reference,
    transaction_wallet: data.transaction_wallet,
    crypto_amount: data.crypto_amount,
    reservation_exp_date: data.reservation_exp_date,
    checkout_iframe: data.checkout_iframe,
  }))

  await dispatch(updateUITheme(tenant))

  return step
}

export const goBack = () => async (dispatch, getState) => {
  const { session_state_guid: state_guid, session_guid: guid } = await getState().session
  if (!state_guid) return null

  let session: any = null

  try {
    session = await api.goBack(state_guid, guid)
  } catch(e) {
    await dispatch(manageSnackBar({
      message: returnAxiosError(e),
      opened: true,
    }))
  }

  if (!session) return await dispatch(handleRouteChange(false))

  const {
    data,
    device_type,
    options,
    order_id,
    session_guid,
    session_state_guid,
    transaction_mode,
    transaction_reference,
    step,
    session_amount,
    session_tip,
    tenant,
    session_type,
    active,
  } = session

  if (!active && device_type === DEVICE_TYPES.TERMINAL && session_type !== SESSION_TYPE.MANUAL) {
    closeTerminalSession()
  }

  await dispatch(setSession({
    active,
    session_guid,
    session_state_guid,
    options,
    data,
    transaction_mode,
    transaction_reference: transaction_reference || null,
    order_id,
    session_amount,
    session_tip,
    device_type,
    step,
    tenant,
    currencies: data.currencies,
    default_currency_guid: data.default_currency_guid,
    pre_transaction_reference: data.pre_transaction_reference,
    transaction_wallet: data.transaction_wallet,
    crypto_amount: data.crypto_amount,
    reservation_exp_date: data.reservation_exp_date,
    checkout_iframe: data.checkout_iframe,
  }))

  await dispatch(updateUITheme(tenant))

  return step
}

export const getFee = (amount = 0, tips = 0) => async (dispatch, getState) => {
  const stateGuid = await getState().session.session_state_guid
  try {
    return await api.getTransactionAmount({ amount, tips }, stateGuid)
  } catch (e) {
    dispatch(manageSnackBar({
      message: e.message,
      opened: true,
    }))
  }
}

export const getFullFee = (amount = 0, tips = 0) => async (dispatch, getState) => {
  const stateGuid = await getState().session.session_state_guid
  try {
    return await api.getFullTransactionAmount({ amount, tips }, stateGuid)
  } catch (e) {
    dispatch(manageSnackBar({
      message: e.message,
      opened: true,
    }))
  }
}

export const verifyWyreSms = (code) => async (dispatch, getState) => {
  try {
    const transaction_reference = getState().session.transaction_reference
    const { localization } = getState().ui
    const result = await api.verifyTransactionSMS(transaction_reference, code)
    if (!result) return createSimpleResp(
      false,
      getLocalizedString('sms_code.errors.code_mismatch', localization),
    )

    return createSimpleResp(true)
  } catch (e) {
    const err = returnAxiosError(e)
    console.log('error in verifySms', err)
    return createSimpleResp(false, err)
  }
}

export const verifyWyreCardCode = (code) => async (dispatch, getState) => {
  try {
    const transaction_reference = getState().session.transaction_reference
    const { localization } = getState().ui
    const result = await api.verifyTransactionCard(transaction_reference, code)
    if (!result) return createSimpleResp(
      false,
      getLocalizedString('bank_code.errors.code_mismatch', localization),
    )
    return createSimpleResp(true)
  } catch (e) {
    const err = returnAxiosError(e)
    return createSimpleResp(false, err)
  }
}


function createResponse(success = false, error = null) {
  return {
    success,
    error,
  }
}

export const sentOTPSMS = (phone) => async () => {
  try {
    return createResponse(await api.sentOTPSMS(phone))
  } catch (e) {
    return createResponse(false, e.message)
  }
}

export const checkOTPSMS = (phone: string, code: string) => async () => {
  try {
    return createResponse(await api.checkOTPSMS(phone, code))
  } catch (e) {
    return createResponse(false, e.message)
  }
}

export const keepOrderSession = () => async (dispatch, getState) => {
  const { session_guid, active: sessionActive, device_type, session_type } = getState().session
  const { openedExpiredSessionPopup } = getState().ui
  try {
    if (session_guid && sessionActive) {
      const { is_uniq, active } = await api.keepOrderSession(session_guid)
      if (device_type === DEVICE_TYPES.WEB && typeof active !== 'undefined' && !active && !openedExpiredSessionPopup) {
        dispatch(manageExpiredSessionPopup(true))
      }
      if (!is_uniq ||
          (!active && device_type === DEVICE_TYPES.TERMINAL &&
              session_type !== SESSION_TYPE.MANUAL)) closeTerminalSession()
    }
  } catch (e) {}
}

export const sentOTPEmail = (email) => async (dispatch, getState) => {
  const tenant_id = getState().session.tenant.tenant_id
  const requestData: any = { email }
  if (tenant_id) requestData.tenant_id = tenant_id

  try {
    return createResponse(await api.sentOTPEmail(requestData))
  } catch (e) {
    return createResponse(false, e.message)
  }
}

export const checkOTPEmail = (email: string, code: string) => async () => {
  try {
    return createResponse(await api.checkOTPEmail({ email, code }))
  } catch (e) {
    return createResponse(false, e.message)
  }
}

export const resetForm = () => async (dispatch) => {
  try {
    // Android - POS terminal function, that will be injected in WebView
    // @ts-ignore
    await Android.transactionComplete()
  } catch(e) {
    // ignore this error
    console.log(e)
  }

  await dispatch({
    type: RESET_FORM,
  })
}

export const setSession = payload => ({
  type: SET_SESSION_DATA,
  payload,
})

export const setReference = reference => async (dispatch, getState) => {
  const transaction_reference = getState().session.transaction_reference
  if (!transaction_reference && transaction_reference !== reference) {
    dispatch(setSession({ transaction_reference: reference }))
  }
}

export const processToken = () => async () => {
  const dateNow = new Date().getTime()/1000
  const token = localStorage.getItem('auth_token')
  const refresh = localStorage.getItem('refresh_token')
  const decodedToken = jwt.decode(token)
  const decodedRefresh = jwt.decode(refresh)


  if (!token || !refresh) return

  if (decodedRefresh?.exp < dateNow) {
    localStorage.clear()
  }

  if (decodedRefresh?.exp < dateNow && decodedToken?.exp < dateNow) {
    localStorage.clear()
    return
  }

  if (decodedToken?.exp < dateNow) {
    await refreshToken(refresh)
    console.log('REFRESH TOKEN')
  }
}

export const validateCustomerCard = card => async (dispatch, getState) => {
  const { first_name, last_name } = await getState().session.data
  return await api.validateCustomerCard({
    first_name,
    last_name,
    card,
  })
}
