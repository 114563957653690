import React, { createContext, ReactNode } from 'react'
import { useMetamask, useWalletConnect } from './hooks'

export const PaymentMethodContext = createContext(null)

export const PaymentMethodsProvider = ({ children }: { children: ReactNode }) => {
  const metamask = useMetamask()
  const walletConnect = useWalletConnect()

  const methods = { metamask, walletConnect }

  return (<PaymentMethodContext.Provider value={methods}>{children}</PaymentMethodContext.Provider>)
}
