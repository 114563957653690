import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { manageSnackBar } from 'redux/actions/uiActions'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    whiteSpace: 'break-spaces',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  action: {
    alignItems: 'flex-start',
    padding: '5px 0',
  },
  icon: {
    alignItems: 'center',
  },
}))

const CustomSnackBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { message, opened, duration, severity } = useSelector((state) => state.ui.snackBar)
  const autoClose = (event, reason) => {
    if (reason !== 'timeout') return
    dispatch(
      manageSnackBar({
        message: '',
        opened: false,
      }),
    )
  }
  const handleClose = () => {
    dispatch(
      manageSnackBar({
        message: '',
        opened: false,
      }),
    )
  }

  return opened && (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={opened}
      autoHideDuration={duration}
      onClose={autoClose}
    >
      <Alert
        severity={severity}
        classes={{
          action: classes.action,
          icon: classes.icon,
        }}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        {message}{' '}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackBar
