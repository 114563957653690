import axios from 'axios'
import config from '../config'
import { decryptBody, encryptBody } from 'helpers'
import { ENV_MODE } from '../constants'


export const axiosInstance = axios.create({
  baseURL: `${config.API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const applicationAxiosInstance = axios.create({
  baseURL: `${config.API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

applicationAxiosInstance.interceptors.request.use(function (request) {
  if (process.env.NEXT_PUBLIC_ENV_MODE === ENV_MODE.DEVELOPMENT) {
    console.log(`%cRequest to ${request.url}`, 'color:green', request.data)
  }
  request.data = { __data: encryptBody(request.data) }
  return request
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

applicationAxiosInstance.interceptors.response.use(function(response) {
  response.data = decryptBody(response.data)
  if (process.env.NEXT_PUBLIC_ENV_MODE === ENV_MODE.DEVELOPMENT) {
    console.log(`%cResponse from ${response.config.url}`, 'color:red', response.data)
  }
  return response
}, function(error){
  return Promise.reject(error)
})


axiosInstance.interceptors.request.use(function (request) {
  return request
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(function(response) {
  return response
}, function(error){
  return Promise.reject(error)
})
