import {
  SET_CURRENCY_LIST,
  SET_CURRENT_ADDRESS,
  SET_CURRENT_NETWORK,
  SET_SELECTED_CURRENCY,
  SET_SELECTED_PAYMENT_METHOD, SET_TRANSACTION_HASH,
} from '../actionTypes/payment'
import { CONNECT_WALLET_METHODS } from '../../constants/payment'
import { Currency, Network } from '../../components/modals/SelectCurrencyModal/type'

interface TransactionInitialState {
  currencyList: Currency[],
  selectedCurrency: Currency | null,
  selectedPaymentMethod: CONNECT_WALLET_METHODS | null,
  currentAddress: string | null,
  transactionHash: string | null,
  currentNetwork: Network | null,
}

const initialState: TransactionInitialState = {
  currencyList: [],
  selectedCurrency: null,
  selectedPaymentMethod: null,
  currentAddress: null,
  currentNetwork: null,
  transactionHash: null,
}

export default function payment(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload,
      }
    case SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      }
    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      }
    case SET_CURRENT_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      }
    case SET_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.payload,
      }
    case SET_TRANSACTION_HASH:
      return {
        ...state,
        transactionHash: action.payload,
      }
    default:
      return state
  }
}
