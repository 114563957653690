import { RESET_FORM, SET_SESSION_DATA } from '../actionTypes/session'
import { DEVICE_TYPES, PAYMENT_PROCESSORS, SESSION_TYPE, TRANSACTION_MODE } from '../../constants'

type CurrencyType = {
  currency_guid: string
  type: string
  symbol: string
  name: string
  price: number
  is_default: boolean
  token_address?: string
  icon_url: string
  decimal: number
}

export interface SessionState {
  session_guid: string
  session_state_guid: string
  options: {
    merchant_enabled: boolean
    allow_tips: boolean
    enable_amount_confirmation: boolean
    enable_otp_sms: boolean
    enable_otp_email: boolean
    full_customer_session: boolean
    business_as: string
    disclaimer_version: number
    disclaimer: string
    logo_url: string
    skip_email_request: boolean
  },
  data: {
    first_name: string
    last_name: string
    email: string
    address: {
      address: string,
      zip_code: string | number
      country: string
      state: string
      city: string
    },
    address_home: {
      address: string,
      zip_code: string | number
      country: string
      state: string
      city: string
    },
    phone_number: string
    amount: number
    tip: number
  },
  tenant: {
    company_name: string
    logo_url: string
    primary_color: string
    secondary_color: string
    api_url: string
    tenant_id: number
  },
  transaction_mode: TRANSACTION_MODE
  payment_processor: PAYMENT_PROCESSORS
  step: string
  session_tip: number
  session_amount: number
  complete: boolean
  order_id: string
  transaction_reference: string
  currencies?: CurrencyType[],
  pre_transaction_reference: string
  default_currency_guid: string
  transaction_wallet: string
  device_type: DEVICE_TYPES
  active: boolean
  session_type: SESSION_TYPE,
  extraPP: string,
}

const initialState: SessionState = {
  session_guid: null,
  session_state_guid: null,
  options: {
    merchant_enabled: null,
    allow_tips: null,
    enable_amount_confirmation: null,
    enable_otp_sms: null,
    enable_otp_email: null,
    full_customer_session: null,
    business_as: null,
    disclaimer_version: null,
    disclaimer: '{}',
    logo_url: null,
    skip_email_request: null,
  },
  data: {
    first_name: null,
    last_name: null,
    email: null,
    address: {
      address: null,
      zip_code: null,
      country: null,
      state: null,
      city: null,
    },
    address_home: {
      address: null,
      zip_code: null,
      country: null,
      state: null,
      city: null,
    },
    phone_number: null,
    amount: null,
    tip: null,
  },
  tenant: {
    company_name: null,
    logo_url: null,
    primary_color: null,
    secondary_color: null,
    api_url: null,
    tenant_id: null,
  },
  transaction_mode: null,
  payment_processor: null,
  step: null,
  session_tip: null,
  session_amount: null,
  session_type: null,
  complete: null,
  order_id: null,
  currencies: null,
  default_currency_guid: null,
  pre_transaction_reference: null,
  transaction_wallet: null,
  transaction_reference: null,
  device_type: null,
  active: null,
  extraPP: null,
}

export default function session(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_FORM:
      return {
        ...state,
        session_guid: initialState.session_guid,
        session_state_guid: initialState.session_state_guid,
        data: initialState.data,
        order_id: initialState.order_id,
        transaction_reference: initialState.transaction_reference,
        payment_processor: initialState.payment_processor,
        session_tip: initialState.session_tip,
        session_amount: initialState.session_amount,
        currencies: initialState.currencies,
        extraPP: initialState.extraPP,
      }
    default:
      return { ...state }
  }
}
