import {
  SET_TRANSACTION_CARD,
} from '../actionTypes/transaction'
import { RESET_FORM } from '../actionTypes/session'

interface TransactionInitialState {
  card: {
    number: string
    exp_date: string
    cvv: string
  },
}

const initialState: TransactionInitialState = {
  card: {
    number: null,
    exp_date: null,
    cvv: null,
  },
}

export default function transaction(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSACTION_CARD:
      return {
        ...state,
        card: action.payload,
      }
    case RESET_FORM:
      return {
        ...state,
        ...initialState,
      }
    default:
      return { ...state }
  }
}
