export const ERROR_TOAST_TITLE = 'Error notification'
export const WARNING_TOAST_TITLE = 'Warning notification'
export const NETWORK_ERROR_TEXT = 'Network Error'


export enum APP_API_ERROR_CODES {
  CARD_CUSTOMER_INVALID = 'CARD_CUSTOMER_INVALID',
}

export const API_ERROR_CODES_MESSAGES = {
  [APP_API_ERROR_CODES.CARD_CUSTOMER_INVALID]: 'Unable to read this card. Please, try again or enter manually',
  EMAIL_EXISTS: 'Email already in use',
  ERROR: 'An Error has Occurred',
}

