export enum ENV_MODE {
  PRODUCTION = 'production',
  DEVELOPMENT = 'staging'
}

export enum TRANSACTION_MODE {
  TEST = 'T',
  PRODUCTION = 'P'
}

export enum PAYMENT_PROCESSORS {
  TRANSAK = 'transak',
  BANXA = 'banxa-mocked',
  BANXA_SVA = 'banxa-sva',
  TRANSAK_SVA = 'transak-sva',
  BANXA_NFT = 'banxa-nft',
  YLOADS = 'yload',
  NFTPAY = 'nftpay',
  QUILTT = 'quiltt',
}

export enum TRANSACTION_ENV {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development'
}

export enum PHONE_NUMBER_TYPE {
  LANDLINE = 'landline',
  MOBILE = 'mobile',
  VOIP = 'voip',
}

export enum MERCHANT_WEBHOOKS_TYPES {
  SETTLEMENTS = 'settlements'
}

export enum DEVICE_TYPES {
  TERMINAL = 'terminal',
  WEB = 'web'
}

export enum DOCUMENT_TYPES {
  DRIVER_LICENSE = 'DL',
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT'
}

export enum LOGO_TYPES {
  LOCATION = 'LOCATION',
  TENANT = 'TENANT'
}

export enum SESSION_TYPE {
  MANUAL = 'manual',
  IDLE = 'idle',
  COMBINE = 'combine',
}

export enum STEPS {
  // BASE
  STATUS = 'status',
  DISCLOSURE = 'disclosure',
  PP_INFO = 'pp_info',

  PAYMENT = 'payment',
  BUY_CRYPTO = 'buy_crypto',
  WALLET_ADDRESS = 'wallet_address',
  WALLET_PRIVATE_KEY = 'wallet_private_key',

  //WYRE
  CUSTOMER_VALIDATION = 'customer_validation',
  PP_VERIFY_SMS = 'pp_verify_sms',
  PP_VERIFY_CARD = 'pp_verify_card',
  AMOUNT_WITH_TIPS = 'amount_with_tips',
  DO_TRANSACTION_WEB = 'transaction_data',
  ENTER_PHONE = 'phone',
  ENTER_EMAIL = 'email',
  SCAN_DOCUMENT = 'scan_documents',
  ENTER_AMOUNT = 'enter_amount',
  ENTER_TIP = 'enter_tip',
  READ_CARD = 'read_card',
  ENTER_ZIP = 'enter_zip',
  DO_TRANSACTION_POS = 'verify_card',
  FORCED_FAILED = 'forced_failed',

  //BANXA
  CREATE_PRE_TRANSACTION = 'create_pre_transaction',
  WALLET_WELCOME = 'wallet_welcome',
  WALLET_PHONE_NUMBER = 'wallet_phone_number',
  WALLET_VERIFY_PHONE_NUMBER = 'wallet_verify_phone_number',
  WALLET_DEPOSIT_OFFER = 'wallet_deposit_offer',
  BANXA_PAYMENT = 'banxa_payment',
  WALLET_ACCOUNT = 'wallet_account',
  WALLET_DEPOSIT_INFO = 'wallet_deposit_info',
  COMBINED_INIT = 'combined_init'
}

export const MAP_PAYMENT_PROCESSORS = {
  [PAYMENT_PROCESSORS.BANXA]: 'Banxa',
  [PAYMENT_PROCESSORS.TRANSAK]: 'Transak',
  [PAYMENT_PROCESSORS.TRANSAK_SVA]: 'Transak',
  [PAYMENT_PROCESSORS.BANXA_SVA]: 'Banxa',
}

export const COMPANY_NAME = process.env.NEXT_PUBLIC_DEFAULT_NAME || ''
export const COMPANY_LOGO_URL = process.env.NEXT_PUBLIC_DEFAULT_LOGO_URL || ''
export const COMPANY_SUPPORT_EMAIL = process.env.NEXT_PUBLIC_DEFAULT_SUPPORT_EMAIL || 'support@company.com'
export const TRANSAK_API_KEY = process.env.NEXT_PUBLIC_TRANSAK_API_KEY || ''
export const TRANSAK_ENVIRONMENT = process.env.NEXT_PUBLIC_TRANSAK_ENVIRONMENT || ''
export const TRANSAK_CRYPTO_CURRENCY = process.env.NEXT_PUBLIC_TRANSAK_CRYPTO_CURRENCY || ''
export const TRANSAK_PAYMENT_METHOD = process.env.NEXT_PUBLIC_TRANSAK_PAYMENT_METHOD || ''
export const TRANSAK_FIAT_CURRENCY = process.env.NEXT_PUBLIC_TRANSAK_FIAT_CURRENCY || ''
export const TRANSAK_NETWORK = process.env.NEXT_PUBLIC_TRANSAK_NETWORK || ''
export const QUILTT_CONNECTOR_ID = process.env.NEXT_PUBLIC_QUILTT_CONNECTOR_ID || ''


export const DATE_FORMAT = 'MM/DD/YY'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} hh:mm`

export const MARKETPLACE_URL = 'https://znftmarket.com'
