const { version } = require('../package.json')

module.exports = {
  getSentryReleaseVersion: () => {
    const CI_JOB_ID = process.env.NEXT_PUBLIC_CI_JOB_ID
    const ENV_MODE = process.env.NEXT_PUBLIC_ENV_MODE

    return `${ENV_MODE}-${version}.${CI_JOB_ID}`
  },
  getAppVersion: () => {
    const CI_JOB_ID = process.env.NEXT_PUBLIC_CI_JOB_ID
    const CI_BUILD_ID = process.env.NEXT_PUBLIC_CI_BUILD_ID

    return `v ${version}.${CI_JOB_ID || CI_BUILD_ID || 0}`
  },
}
