const palette = {
  primary: {
    main: '#C80F7C',
    hover: '#8e0040',
  },
  secondary: {
    main: '#1C8FBD',
    hover: '#187295',
  },
  success: {
    main: '#4caf50',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  warning: {
    main: '#ff9800',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  error: {
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  info: {
    main: '#0085FF',
    hover: '#64b5f6',
    dark: '#1976d2',
    contrastText: '#fff',
  },
  text: {
    primary: '#fff',
    // secondary: '#424242',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
  },
  action: {
    active: '#fff',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
  background: {
    paper: 'rgba(255, 255, 255, 0.07)',
    default: '#E5E5E5',
    level2: '#333',
    level1: '#212121',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  black: '#000',
  white: '#fff',
}

export const theme = {
  palette,
  typography: {
    fontFamily: ['SF Pro Display Regular', 'Roboto', 'sans-serif'].join(','),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiDialog: {
      paper: {
        // backgroundColor: palette.,
      },
    },
  },
}
