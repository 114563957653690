import {
  SET_CURRENCY_LIST,
  SET_CURRENT_ADDRESS, SET_CURRENT_NETWORK,
  SET_SELECTED_CURRENCY,
  SET_SELECTED_PAYMENT_METHOD, SET_TRANSACTION_HASH,
} from '../actionTypes/payment'
import { CONNECT_WALLET_METHODS } from '../../constants/payment'
import { RootState } from '../reducers/rootReducer'
import api from '../../api'
import { manageSnackBar } from './uiActions'
import { returnAxiosError, setPaymentMethodToLS } from '../../helpers'
import { Currency, Network } from '../../components/modals/SelectCurrencyModal/type'

export const setCurrentCurrency = (payload: Currency) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_CURRENCY, payload })
}

export const setPaymentMethod = (payload: CONNECT_WALLET_METHODS | null) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_PAYMENT_METHOD, payload })
  setPaymentMethodToLS(payload)
}

export const setCurrencyList = (payload) => ({
  type: SET_CURRENCY_LIST,
  payload,
})

export const setCurrentAddress = (payload: string) => ({
  type: SET_CURRENT_ADDRESS,
  payload,
})

export const setCurrentNetwork = (payload: Network) => ({
  type: SET_CURRENT_NETWORK,
  payload,
})

export const setTransactionHash = (payload: string | null) => ({
  type: SET_TRANSACTION_HASH,
  payload,
})


export const getCurrencies = () => async (dispatch, getState: () => RootState) => {
  const { session_state_guid } = getState().session

  try {
    const {  data  } = await api.getCurrencyList(session_state_guid)
    dispatch(setCurrencyList(data))
  } catch(e) {
    await dispatch(manageSnackBar({
      message: returnAxiosError(e),
      opened: true,
    }))
  }
}
