import { SET_TRANSACTION_CARD } from '../actionTypes/transaction'
import { TRANSACTION_STATUSES } from '../../constants/transaction'
import { restrictCardNumberInformation, returnAxiosError, sleep } from '../../helpers'
import api from '../../api'
import { logEvent } from 'services/sentryService'
import { setReference, setSession } from './sessionActions'
import { handleCurrencyLoading, manageSnackBar } from './uiActions'
import { RootState } from '../reducers/rootReducer'

export const trackTransaction = (payload, status: TRANSACTION_STATUSES[] = []) => async (dispatch, getState) => {
  let response: any = {}

  const reference = getState().session.transaction_reference

  while (!(status.includes(response?.status))) {
    response = await api.trackTransaction(payload)
    console.log('response', response)
    await sleep(2000)

    if (!reference && response?.reference) dispatch(setReference(response.reference))
    // if (response?.step && currentStep !== response.step) {
    //   await dispatch(setSession({
    //     step: response.step,
    //   }))
    // }
  }

  return response
}

export const setTransactionCard = (payload) => (dispatch) => {
  logEvent(`Saved ${restrictCardNumberInformation(payload.number)} card`)

  dispatch({
    type: SET_TRANSACTION_CARD,
    payload,
  })
}

export const changeTransactionCurrency = (payload) => async (dispatch, getState) => {
  try {
    dispatch(handleCurrencyLoading(true))
    const sessionStateGuid = getState().session.session_state_guid

    const { sessionState } = await api.changeCurrency(sessionStateGuid, { currency_guid: payload })
    const { data } = sessionState

    await dispatch(setSession({
      default_currency_guid: data.default_currency_guid,
      transaction_wallet: data.transaction_wallet,
      crypto_amount: data.crypto_amount,
      reservation_exp_date: data.reservation_exp_date,
    }))
  } finally {
    dispatch(handleCurrencyLoading(false))
  }
}

export const setPendingStatus = (payload) => async (dispatch, getState: () => RootState) => {
  const { session_state_guid } = getState().session

  let res: any = null
  try {
     res = await api.setPendingStatusForTxn(payload)
    return res
  } catch(e) {
    await dispatch(manageSnackBar({
      message: returnAxiosError(e),
      opened: true,
    }))
  }
}

