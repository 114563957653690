export enum TRANSACTION_STATUSES {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  REFUNDED = 'REFUNDED',
  CHARGEBACK = 'CHARGEBACK',
  ABANDONED = 'ABANDONED',
  PENDING = 'PENDING',
  CUSTOMER_VALIDATION = 'CUSTOMER_VALIDATION',
}

export enum PRINTER_STATUSES {
  STARTED = 4,
  HOT_AND_NO_PAPER = 3,
  HOT = 2,
  NO_PAPER = 1,
  NORMAL = 0,
  QUERY_FAIL= -1,
  TIME_OUT = -2,
}

export const MASK_OPTIONS = {
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  includeThousandsSeparator: false,
  requireDecimal: false,
  allowLeadingZeroes: false,
}

export const MASK_CURRENCY = {
  prefix: '$',
  suffix: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  includeThousandsSeparator: false,
  requireDecimal: false,
  allowLeadingZeroes: false,
}

export const TEST_CARD_NUMBERS = [
  '5457082231440939',
  '4443801001934858',
  '5167820105018518',
  '4443801001237724',
  '5167985560032633',
  '5168755529251072',
  '5156767977808901',
  '5168757339754121',
  '5168757350765329',
  '4149499108248343',
]
