import React, { useEffect, useRef } from 'react'
import { resetForm, keepOrderSession } from '../../redux/actions/sessionActions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers/rootReducer'
import { selectNavigator } from '../../redux/selectors/session'
import { useRouter } from 'next/router'
import axios from 'axios'
import { getAppVersion } from '../../helpers/config'
import { DEVICE_TYPES } from '../../constants'
import { logEvent } from '../../services/sentryService'


declare const window: Window &
    typeof globalThis & {
  cardReadEvent: Event
  backButtonEvent: Event
  docScannedEvent: Event
}

const Listeners = () => {
  const { device_type } = useSelector((state: RootState) => state.session)
  const { paths } = useSelector(selectNavigator)
  const dispatch = useDispatch()
  const router = useRouter()
  const interval = useRef(null)
  const versionRoutineArgs = useRef(null)
  const intervalArgs = useRef(null)
  const keepAliveRoutine = useRef(null)

  intervalArgs.current = {
    excludePages: [
      // paths.status(),
    ],
    path: router.asPath,
  }

  versionRoutineArgs.current = {
    excludePages: [
      paths.pp_verify_card(),
      paths.pp_verify_sms(),
      paths.status(),
      paths.forced_failed(),
    ],
    path: router.asPath,
    device_type,
  }

  useEffect(() => {
    initEvents()
    interval.current = setInterval(checkFormVersion, 5000)
    keepAliveRoutine.current = setInterval(startKeepAliveRoutine,5000)
    initTabCloseListener()
    return () => {
      clearInterval(interval.current)
      clearInterval(keepAliveRoutine.current)
    }
  }, [])

  const initTabCloseListener = () => {
    window.onbeforeunload = closingCode

    function closingCode(){
      logEvent('Payment form has been closed.')
      return null
    }
  }


  const initEvents = () => {
    window.cardReadEvent = new Event('cardRead')
    window.backButtonEvent = new Event('backButton')
    window.docScannedEvent = new Event('docScanned')
  }

  const startKeepAliveRoutine = async () => {
    if (intervalArgs.current.excludePages.some(item => intervalArgs.current.path.includes(item))) return
    await dispatch(keepOrderSession())
  }

  const checkFormVersion = async () => {
    const { excludePages, path, device_type } = versionRoutineArgs.current || {}

    if (excludePages.some(item => path.includes(item))) return

    const { data } = await axios.get('/api/version')
    if (data.version === getAppVersion()) return

    if (device_type !== DEVICE_TYPES.TERMINAL) {
      await router.reload()
      return
    }

    logEvent('Payment form closed.')
    await dispatch(resetForm())
  }

  return null
}

export default Listeners
