import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalizedString, getPaymentMethodFromLS } from 'helpers'
import { RootState } from '../redux/reducers/rootReducer'
import { TRANSACTION_STATUSES } from '../constants/transaction'
import { trackTransaction } from '../redux/actions/transaction'
import { selectCurrentPaymentMethod } from '../redux/selectors/payment'
import { CONNECT_WALLET_METHODS } from '../constants/payment'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import {
  setCurrentAddress,
  setCurrentNetwork,
  setPaymentMethod,
  setTransactionHash,
} from '../redux/actions/paymentActions'
import { PaymentMethodContext } from './providers'
import { CurrenciesTypes, networks } from '../constants/chainIds'
import { processToken } from '../redux/actions/sessionActions'
import { useRouter } from 'next/router'


export function useLocalization() {
  const { localization } = useSelector((state: RootState) => state.ui)
  return (key) => getLocalizedString(key, localization)
}

export const usePaymentMethodsHandler = () => {
  const currentPaymentMethod = useSelector(selectCurrentPaymentMethod)

  const { metamask, walletConnect } = useContext(PaymentMethodContext)
  const { haveMetamask, requestConnectMetamask, metamaskAddress, resetMetamaskWallet, metamaskNetwork } = metamask
  const { initWCSession, killWCSession, wcAddress, wcNetwork } = walletConnect

  const dispatch = useDispatch()

  useEffect(() => {
    setInitialPaymentMethod()
  }, [])

  useEffect(() => {
    switch (currentPaymentMethod) {
      case CONNECT_WALLET_METHODS.METAMASK:
        dispatch(setCurrentNetwork(metamaskNetwork))
        break
      case CONNECT_WALLET_METHODS.TRUST_WALLET:
        dispatch(setCurrentNetwork(wcNetwork))
        break
      default:
        break
    }
  }, [metamaskNetwork, wcNetwork, currentPaymentMethod])

  useEffect(() => {
    switch (currentPaymentMethod) {
      case CONNECT_WALLET_METHODS.METAMASK:
        dispatch(setCurrentAddress(metamaskAddress))
        break
      case CONNECT_WALLET_METHODS.TRUST_WALLET:
        dispatch(setCurrentAddress(wcAddress))
        break
      default:
        // dispatch(setCurrentAddress(null))
        break
    }
  }, [metamaskAddress, wcAddress])

  useEffect(() => {
    connectWallet(currentPaymentMethod)

    return () => {
      killWCSession()
      resetMetamaskWallet()
    }
  }, [currentPaymentMethod])

  const setInitialPaymentMethod = () => {
    const paymentMethod = getPaymentMethodFromLS()
    if (paymentMethod) dispatch(setPaymentMethod(paymentMethod as CONNECT_WALLET_METHODS))
  }

  const setDirectAddress = () => {
    const createdWalletJSON = localStorage.getItem('createdWallet')
    if (createdWalletJSON && createdWalletJSON !== 'null') {
      const createdWallet = JSON.parse(createdWalletJSON)
      dispatch(setCurrentAddress(createdWallet?.address || null))
    } else {
      dispatch(setCurrentAddress(null))
      dispatch(setPaymentMethod(null))
    }
  }

  const connectWallet = async (currentPaymentMethod: CONNECT_WALLET_METHODS) => {
    switch (currentPaymentMethod) {
      case CONNECT_WALLET_METHODS.METAMASK:
        if (!haveMetamask) return
        await requestConnectMetamask()
        break
      case CONNECT_WALLET_METHODS.TRUST_WALLET:
        await initWCSession()
        break
      case CONNECT_WALLET_METHODS.DIRECT:
        setDirectAddress()
        break
      default:
        dispatch(setCurrentAddress(null))
        break
    }
  }

}

export const useWalletConnect = () => {
  const connector = new WalletConnect({
    bridge: 'https://test.walletconnect.org', // Required
    qrcodeModal: QRCodeModal,
  })
  const paymentMethod = useSelector(selectCurrentPaymentMethod)
  const [wcAddress, setWcAddress] = useState(null)
  const [wcNetwork, setWcNetwork] = useState(null)

  const dispatch = useDispatch()

  const initWCSession = () => {
    // Check if connection is already established
    if (!connector.connected) {
      // create new session
      connector.createSession()
    }

    subscribeWC()

    const address = connector.accounts[0]
    const chainId = connector.chainId

    const network = networks.find(({ chain_id }) => chainId === +chain_id)
    setWcAddress(address)
    setWcNetwork(network || null)

  }

  const killWCSession = () => {
    setWcAddress(null)
    setWcNetwork(null)
    unsubscribeWC()
    try {
      // connector.killSession()

    } catch (e) {
      console.log(e)
    }
  }

  const subscribeWC = () => {
    // Subscribe to connection events
    connector.on('connect', (error, payload) => {
      if (error) {
        console.log(error)
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0]
      setWcAddress(accounts[0])
      const network = networks.find(({ chain_id }) => +chain_id === chainId)
      setWcNetwork(network || null)
    })

    connector.on('session_update', (error, payload) => {
      if (error) {
        console.log(error)
      }

      // Get updated accounts and chainId
      const { accounts, chainId } = payload.params[0]
      setWcAddress(accounts[0])
      const network = networks.find(({ chain_id }) => +chain_id === chainId)
      setWcNetwork(network || null)
    })

    connector.on('disconnect', (error, payload) => {
      if (error) {
        console.log(error)
      }
      // Delete connector
      setWcAddress(null)
      setWcNetwork(null)
      dispatch(setPaymentMethod(null))
    })
  }

  const unsubscribeWC = () => {
    connector.off('connect')
    connector.off('session_update')
    connector.off('disconnect')
  }

  const requestWCPayment = async ({ to, wei }) => {
    if (paymentMethod !== CONNECT_WALLET_METHODS.TRUST_WALLET) return

    const value = String(wei).toString(16)


    await connector.sendTransaction({
      from: wcAddress,
      to,
      value,
    })
      .then(txHash => {
        dispatch(setTransactionHash(txHash))
      })
      .catch(error => console.log(error))
  }

  return {
    initWCSession,
    requestWCPayment,
    killWCSession,
    wcAddress,
    wcNetwork,
  }
}

export const useMetamask = () => {
  const paymentMethod = useSelector(selectCurrentPaymentMethod)
  const [haveMetamask, setHaveMetamask] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [metamaskNetwork, setMetamaskNetwork] = useState(null)
  const [metamaskAddress, setAddress] = useState(null)
  const ethClient = useRef(null)
  const changeAccountListener = useRef(null)
  const changeChainListener = useRef(null)
  const connectListener = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    bootstrap()
    return () => {
      if (!ethClient.current) return
      if (changeAccountListener.current) ethClient.current.removeListener('accountsChanged', changeAccountListener.current)
      if (changeChainListener.current) ethClient.current.removeListener('chainChanged', changeChainListener.current)
      if (connectListener.current) ethClient.current.removeListener('connect', connectListener.current)
    }
  }, [])

  useEffect(() => {
    onConnect()
  }, [isConnected])

  const onConnect = async () => {
    if (!isConnected) return

    changeAccountListener.current = ethClient.current.on('accountsChanged', async (accounts) => {
      setAddress(accounts[0])
      // Handle the new accounts, or lack thereof.
      // "accounts" will always be an array, but it can be empty.
    })

    changeChainListener.current = ethClient.current.on('chainChanged', (chainId) => {
      const network = networks.find(({ chain_id }) => +chain_id === +chainId)
      setMetamaskNetwork(network || null)

      setDefaultChain(chainId)

      // const currency = CHAINS[chainId] && CHAINS[chainId].currency
      // if (currency) setCurrency(currency)
      // Handle the new accounts, or lack thereof.
      // "accounts" will always be an array, but it can be empty.
    })
  }

  const setDefaultChain = async (currentChainId) => {
    if (!ethClient.current) return

    const defaultNetwork =
       networks.find(({ type }) => type === CurrenciesTypes.ROP)

    if (!defaultNetwork) return
    const defaultChainId = defaultNetwork.chain_id

    if (+currentChainId === +defaultChainId) return

    await ethClient.current.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x3' }],
    })
  }

  const bootstrap = async () => {
    const { ethereum } = window

    if (!ethereum) {
      setHaveMetamask(false)
      return
    }
    setHaveMetamask(true)

    ethClient.current = ethereum

    if (!ethClient.current.isConnected()) {
      connectListener.current = ethereum.on('connect', (connectInfo) => {
        console.log('ON CONNECT', connectInfo)
        setIsConnected(true)
      })
      return
    }

    setIsConnected(true)

    const chainId = await ethClient.current.request({ method: 'eth_chainId' })
    const network = networks.find(({ chain_id }) => +chain_id === +chainId)
    setDefaultChain(chainId)
    setMetamaskNetwork(network || null)

    // const accounts = await ethereum.request({
    //   method: 'eth_requestAccounts',
    // })
    //
    // // console.log('ethereum', ethereum)
    // console.log('accounts', accounts)
    // if (accounts?.length) setAddress(accounts[0])
    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    // const balance = await provider.getBalance(accounts[0])
    // const bal = await ethers.utils.formatEther(balance)
    //
    // console.log('balance', balance)
    // console.log('bal', bal)
  }

  const requestConnectMetamask = async () => {
    const accounts = await ethClient.current.request({
      method: 'eth_requestAccounts',
    })

    if (accounts?.length) setAddress(accounts[0])
  }

  const requestMetamaskPayment = async ({ to, wei }) => {
    if (paymentMethod !== CONNECT_WALLET_METHODS.METAMASK) return

    const value = wei.toString(16)

    await ethClient.current.request({
      method: 'eth_sendTransaction',
      params: [{
        from: metamaskAddress,
        to,
        value,
      }],
    })
      .then(txHash => {
        dispatch(setTransactionHash(txHash))
      })
      .catch(error => console.log(error))
  }

  const resetMetamaskWallet = () => {
    setAddress(null)
    // setMetamaskNetwork(null)
  }

  return {
    haveMetamask,
    metamaskAddress,
    isConnected,
    requestConnectMetamask,
    requestMetamaskPayment,
    resetMetamaskWallet,
    metamaskNetwork,
  }

}


export const useDetectCryptoTransaction = () => {
  const { pre_transaction_reference } = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()

  useEffect(() => {
    getStatus()
  }, [pre_transaction_reference])

  const getStatus = async () => {
    if (!pre_transaction_reference) return
    const statuses = [
      TRANSACTION_STATUSES.COMPLETE,
      TRANSACTION_STATUSES.PROCESSING,
      TRANSACTION_STATUSES.FAILED,
      TRANSACTION_STATUSES.REFUNDED,
      TRANSACTION_STATUSES.CHARGEBACK,
    ]

    const response: any = await dispatch(trackTransaction({ pre_transaction_id: pre_transaction_reference }, statuses))
    // if (response) {
    //   dispatch(goNext())
    // }
  }
}

export const useCheckToken = () => {
  const dispatch = useDispatch()
  const INTERVAL_TIME = 60000

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(processToken())
    }, INTERVAL_TIME)

    return () => clearInterval(interval)
  }, [])

}

export const useClientInfo = () => {
  const [clientInfo, setClientInfo] = useState({
    userAgent: '',
    platform: '',
    language: '',
    timeZone: '',
    userLocation: null,
  })

  const fetchClientInfo = async () => {
    const userAgent = navigator.userAgent
    const platform = navigator.platform
    const language = navigator.language
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let userLocation = null
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        userLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setClientInfo((prevData) => ({
          ...prevData,
          userLocation,
        }))
      })
    }
    setClientInfo((prevData: any) => ({
      ...prevData,
      userAgent,
      platform,
      language,
      timeZone,
    }))
  }

  useEffect(() => {
    fetchClientInfo()
  }, [])

  return clientInfo
}

