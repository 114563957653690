import store from '../redux/store'
import {
  COUNTRIES,
  PHONE_CODES,
  USA_STATE_LIST,
} from '../constants/locationData'
import _ from 'lodash'
import moment from 'moment'
import { english } from '../localization/en/en'
import { spanish } from '../localization/es/es'
import { german } from '../localization/de/de'
import { french } from '../localization/fr/fr'
import { COMPANY_LOGO_URL, DEVICE_TYPES, DOCUMENT_TYPES, ENV_MODE } from '../constants'
import { version } from '../package.json'
import { parse } from 'parse-usdl'
import crypto from 'crypto'
import { CONNECT_WALLET_METHODS } from '../constants/payment'
import { uuidv4 } from '@firebase/util'

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return string
}

export function getCountryObjectByValue(value) {
  let countryObj = null
  COUNTRIES.map((country) => {
    if (countryObj) return
    if (country.value === value) countryObj = country
  })

  return countryObj
}

export function checkPassedPhoneByCountryCode(phone, country) {
  if (!country) return ''
  const countryCode = PHONE_CODES.filter((code) => code.code === country)[0]
  if (!phone) {
    return countryCode?.dial_code
  }
  const clearPhone = `+${phone
    .replace(/[^0-9\s]/g, '')
    .split(' ')
    .filter((item) => item !== '')
    .join('')}`
  const separatedByCode = clearPhone.split(countryCode?.dial_code)

  if (separatedByCode.length === 2) {
    return clearPhone
  } else {
    return `${countryCode?.dial_code}${separatedByCode[0].split('+')[1]}`
  }
}

export const N = (number: string | number): number => {
  number = +number || 0
  return Math.round(number * 100000000) / 100000000
}

export const S = (str: string | number) => {
  if (typeof str !== 'number' || isNaN(str)) str = str || ''
  return String(str)
}

export const C = (number, symbol = '') => {
  const round = Math.round(N(number) * 100) / 100

  const match = round.toFixed(2).match(/(-?)(.+)/)
  return match[1] + S(symbol) + match[2]
}

export const roundToTwo = (number: string | number) => {
  number = +number || 0
  return Math.round(number * 100) / 100
}

export const getAmount = (
  value,
): { amount: number; firstPartAmount: number; secondPartAmount: number } => {
  if (!value) {
    return {
      amount: value,
      firstPartAmount: null,
      secondPartAmount: null,
    }
  }

  let calcAmount: string
  let firstPartAmount: string
  let secondPartAmount: string
  const formattedAmount =
    value.toString().split('.').length === 1
      ? value.toString() + '.00'
      : value.toString()

  if (formattedAmount && formattedAmount.toString().split('.').length > 1) {
    const [wholeNumber, fractionalNumber] = formattedAmount.split('.')
    firstPartAmount = wholeNumber
    secondPartAmount = fractionalNumber

    const [firstFraction = 0, secondFraction = 0] = fractionalNumber.split('')

    calcAmount = wholeNumber + '.' + firstFraction + secondFraction
  }
  return {
    amount: N(calcAmount),
    firstPartAmount: N(firstPartAmount),
    secondPartAmount: N(secondPartAmount),
  }
}

export const getStateObject = (value) => {
  if (value) {
    return USA_STATE_LIST.find((state) => state.value === value)
  }
  return null
}

export const getCountryObject = (value) => {
  if (value) {
    return COUNTRIES.find((country) => country.value === value)
  }
  return null
}

export const capitalizeWords = (value) => {
  if (value) {
    return _.chain(value)
      .split(' ')
      .map((item) => _.capitalize(item))
      .join(' ')
      .value()
  }
  return value
}

export const showTerminalLogo = (tenantLogo) => {
  const device_type = store.getState().session.device_type
  if (device_type === DEVICE_TYPES.TERMINAL) {
    return COMPANY_LOGO_URL
  }
  return tenantLogo
}

export const returnAxiosError = (e: any) => {
  let error = e.message
  if (e?.response?.error) error = e.response.error
  if (e?.response?.data?.type) error = e.response.data.type
  if (e?.response?.data?.message) error = e.response.data.message
  if (e?.response?.data?.error) error = e.response.data.error

  return error
}

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(() => resolve(), ms))
}

export const showMiniLCDMessage = (object: object) => {
  const device_type = store.getState().session.device_type
  if (device_type === DEVICE_TYPES.TERMINAL) {
    try {
      // Android - POS terminal function, that will be injected in WebView
      // we are sending only message for now
      // @ts-ignore
      Android.showMiniLcdMessage(object.message)
    } catch (e) {
      console.log(e)
    }
  }
}

export const closeTerminalSession = () => {
  const device_type = store.getState().session.device_type
  if (device_type === DEVICE_TYPES.TERMINAL) {
    try {
      // Android - POS terminal function, that will be injected in WebView
      // we are sending only message for now
      // @ts-ignore
      Android.backToQRScan()
    } catch (e) {
      console.log(e)
    }
  }
}

export const expDatePipe = (value, config) => {
  const { previousConformedValue } = config
  const array = config?.rawValue?.split('/')
  if (array.length > 0 && array[1]?.length > 3) {
    return `${array[0]}/${array[1].slice(2,4)}`
  }
  const [mm] = value.split('/')
  if (mm && +mm[0] !== 0) {
    if (+mm[1] > 2) {
      return previousConformedValue
    }
  }
  return value
}

export const getTransactionEnv = (value) => {
  if (value === 'all') return null
  return value
}

export const getLocalizedString = (key: string, lang: string) => {
  let dest = english

  switch (lang) {
    case 'es':
      dest = spanish
      break
    case 'de':
      dest = german
      break
    case 'fr':
      dest = french
      break
    default:
      break
  }

  return _.get(dest, key, _.get(english, key)) || _.get(english, key)
}

export const getDefaultLanguage = () => {
  const browserLang = navigator.language
  switch (browserLang) {
    case 'en':
      return browserLang
    case 'es':
      return browserLang
    case 'de':
      return browserLang
    default:
      return 'en'
  }
}

export const getDisclosure = (obj, key, index = 0) => {
  if (!obj) return null
  if (obj[key]) return obj[key]
  const jsonKeys = Object.keys(obj)
  if (index + 1 > jsonKeys.length) return null
  return getDisclosure(obj, jsonKeys[index], index + 1)
}

export const toFixedNoRounding = (n1, n2) => {
  const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n2 + '})?', 'g')
  const a = n1.toString().match(reg)[0]
  const dot = a.indexOf('.')
  if (dot === -1) {
    return a + '.' + '0'.repeat(n2)
  }
  const b = n2 - (a.length - dot) + 1
  return b > 0 ? a + '0'.repeat(b) : a
}

export const getSentryReleaseVersion = () => {
  const CI_JOB_ID = process.env.NEXT_PUBLIC_CI_JOB_ID
  const ENV_MODE = process.env.NEXT_PUBLIC_ENV_MODE

  return `${ENV_MODE}-${version}.${CI_JOB_ID}`
}

export const parseWeight = (value) => {
  if (!value) return null
  return N(value.replace(/[^0-9]/g, ''))
}

export const parseHeight = (value) => {
  if (!value) return null
  return N(value.replace(/[^0-9]/g, ''))
}

const formatDLDate = (date) => {
  if (!date) return null
  return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
}

export const parseDL = (dataString) => {
  if (!dataString) return null
  const parsedData = parse(dataString, { suppressErrors: true })

  const customersAddress = {
    city: parsedData.addressCity,
    zip: parsedData.addressPostalCode,
    state: parsedData.addressState,
    address1: parsedData.addressStreet,
  }

  const driverLicense = {
    type: DOCUMENT_TYPES.DRIVER_LICENSE,
    data: {
      vehicle_class: parsedData.jurisdictionVehicleClass,
      restriction_class: parsedData.jurisdictionRestrictionCodes,
      endorsement_codes: parsedData.jurisdictionEndorsementCodes,
      compliance_type: parsedData.complianceType,
      issuer: parsedData.issuer,
      issued_at: formatDLDate(parsedData.dateOfIssue),
      expired_at: formatDLDate(parsedData.dateOfExpiry),
    },
  }

  function getFirstName(parsedData) {
    if (parsedData.firstName) return parsedData.firstName
    if (parsedData.firstNameAndMiddleName) {
      return parsedData?.firstNameAndMiddleName?.split(' ')[0]
    }
    return ''
  }

  function getMiddleName(parsedData) {
    if (parsedData.middleName) return parsedData.middleName
    if (parsedData.firstNameAndMiddleName) {
      return parsedData?.firstNameAndMiddleName?.split(' ')[1]
    }
    return ''
  }

  return {
    first_name: _.capitalize(getFirstName(parsedData)),
    middle_name: _.capitalize(getMiddleName(parsedData)),
    last_name: _.capitalize(parsedData.lastName),
    hair_color: parsedData.hairColor,
    eye_color: parsedData.eyeColor,
    sex: parsedData.sex,
    weight: parseWeight(parsedData.weightLb),
    height: parseHeight(parsedData.height),
    dob: formatDLDate(parsedData.dateOfBirth),
    address: customersAddress,
    ids: [driverLicense],
  }
}

export const amountPipe = (conformedValue, config = {}) => {
  const { rawValue, previousConformedValue } = config
  const [integer, decimal] = conformedValue.split('.')

  if (typeof decimal === 'undefined') {
    if (previousConformedValue) {
      const [prevInteger, prevDecimal] = previousConformedValue.split('.')
      if (prevDecimal) {
        console.log('rawValue',  rawValue)
        return rawValue
      }
    }
  }

  if (typeof integer === 'string' && !integer && decimal) {
    return previousConformedValue
  }

  return conformedValue
}

export const isCountryUS = (c: string): boolean => c === 'US'

export const formatPhone = (v: string): string => {
  if (!v) return ''
  return v.replace(/\D/g, '')
}

export const createSimpleResp = (success = false, error:  string | null = null) => {
  return {
    success,
    error,
  }
}

export const decryptBody = (data) => {
  const iv = Buffer.from(process.env.NEXT_PUBLIC_APP_DECR_IV, 'base64')
  const key = Buffer.from(process.env.NEXT_PUBLIC_APP_DECR_KEY, 'base64')

  const encodedData = Buffer.from(data, 'base64')
  const decipher = crypto.createDecipheriv('aes-256-ctr', key, iv)
  const decryptedText = Buffer.concat([
    decipher.update(encodedData),
    decipher.final(),
  ]).toString('utf-8')
  return JSON.parse(decryptedText)
}

export const encryptBody = (data) => {
  const iv = Buffer.from(process.env.NEXT_PUBLIC_APP_DECR_IV, 'base64')
  const key = Buffer.from(process.env.NEXT_PUBLIC_APP_DECR_KEY, 'base64')
  const cipher = crypto.createCipheriv('aes-256-ctr', key, iv)

  const textToEncrypt = JSON.stringify(data || '')
  return Buffer.concat([
    cipher.update(textToEncrypt),
    cipher.final(),
  ]).toString('base64')
}

export const restrictCardNumberInformation = (number: string): string => {
  return number.toString().replace(/(\d{6})\d{6}/, '$1******')
}

export async function axiosRetry (fn, delay, tries ) {
  function onError(err){
    const triesLeft = tries - 1
    if(!triesLeft){
      throw err
    }
    return sleep(delay).then(() => axiosRetry(fn, delay, triesLeft))
  }

  return await fn().catch(onError)
}

export const convertAddressToLabel = (address: string) => {
  if (!address) return ''

  return `${address.slice(0, 4)}...${address.slice(address.length - 4)}`
}

export const calculateAmount = (priceInUsd: number, coinPrice: number, signsAfterDot = 6) => {
  const [decimal, float] = String(Number(priceInUsd) / Number(coinPrice)).split('.')
  if (float) return `${decimal}.${float.slice(0, signsAfterDot)}`

  return decimal
}

export const setPaymentMethodToLS = (method: CONNECT_WALLET_METHODS) => {
  if (method) {
    localStorage.setItem('paymentMethod', method)
  } else {
    localStorage.removeItem('paymentMethod')
  }
}

export const getPaymentMethodFromLS = () => localStorage.getItem('paymentMethod')

export const parseMaskedAmount = (v) => S(parseFloat(v?.toString()?.replace('$', '')?.replace(/,/g, '')))

export const formatCardNumber = (cardNumber) => {
  if (!cardNumber) return cardNumber
  return cardNumber?.toString().match(/.{1,4}/g).join(' ')
}

export const generateCardNumber = () => {
  let number = ''
  for (let i = 0; i < 16; i++) {
    number += Math.floor(Math.random() * 10)
    if (i % 4 === 3 && i !== 15) {
      number += ' '
    }
  }
  return number
}

export const generateUniqueString = (minLength, maxLength) => {
  const uuid = uuidv4().replace(/-/g, '').substring(0, maxLength)

  if (uuid.length < minLength) {
    return uuid + uuidv4().replace(/-/g, '').substring(0, minLength - uuid.length)
  }

  return uuid
}

export const formatAmount = (amount:string | number): string => {
  const formattedVal = amount.toString()
  if (formattedVal.indexOf('.') !== -1) {
    const parts = formattedVal.split('.')

    if (parts[1].length === 1) {
      parts[1] += '0'
    }

    return parts.join('.')
  } else {
    return formattedVal + '.00'
  }
}

export const getReceiptUrl = (
  domain: string,
  reference: string,
): string | null => {
  if (!domain || !reference) return null
  return `${domain}/r/${reference}`
}

export function generateQrCodeImageForReceipt(domain: string, reference: string): string {
  const receiptUrl = encodeURIComponent(getReceiptUrl(domain, reference))
  return `${process.env.NEXT_PUBLIC_API_URL}/api/qr-code/${receiptUrl}`
}


