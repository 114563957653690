import * as transaction from './transaction'
import * as orderSession from './orderSession'
import * as customer from './customer'
import * as currency from './currency'
import { axiosInstance } from './axiosInstance'


const api = {
  ...transaction,
  ...orderSession,
  ...customer,
  ...currency,
}

export { axiosInstance }

export default api
