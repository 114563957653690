import React, { useEffect , useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BackButton from '../BackButton/BackButton'
import ResetButton from '../ResetButton/ResetButton'
import Version from '../../features/version'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { DEVICE_TYPES } from '../../constants'
import { RootState } from '../../redux/reducers/rootReducer'

interface FormContainerProps {
  onClickBack?: () => void,
  disableBack?: boolean,
  children?: any,
}

const FormContainer: React.FC<FormContainerProps> = (props) => {
  const {
    children,
    onClickBack,
    disableBack = false,
  } = props

  const device_type = useSelector((state: RootState) => state.session.device_type)

  const [backTriggered, setBackTriggered] = useState(false)

  useEffect(() => {
    if (backTriggered) {
      navigateBack()
    }
  }, [backTriggered])

  useEffect(() => {
    setupBackButtonListener()
    return removeResultListener
  }, [])

  const handleBack = () => {
    setBackTriggered(true)
  }

  const setupBackButtonListener = () => {
    if (device_type !== DEVICE_TYPES.TERMINAL) return
    window.addEventListener('backButton', handleBack, false)
  }

  const removeResultListener = () => {
    if (device_type !== DEVICE_TYPES.TERMINAL) return
    window.removeEventListener('backButton', handleBack)
  }

  const clickBackWeb = async () => {
    if (!onClickBack && disableBack && device_type !== DEVICE_TYPES.TERMINAL) return
    await onClickBack()
  }

  const navigateBack = async () => {
    try {
      if (!onClickBack || disableBack || device_type !== DEVICE_TYPES.TERMINAL) return
      await onClickBack()
    } finally {
      setBackTriggered(false)
    }
  }

  const renderBackButton = () => {
    if(onClickBack && device_type === DEVICE_TYPES.WEB){
      return(
        <BackButton onClick={clickBackWeb} buttonDisabled={disableBack}/>
      )
    }
    return <EmptyControl/>
  }

  const renderResetButton = () => {
    if (device_type === DEVICE_TYPES.TERMINAL) {
      return <ResetButton/>
    }
    return null
  }

  const renderLanguageSelector = () => {
    if (device_type !== DEVICE_TYPES.TERMINAL) {
      return <LanguageSelector />
    }
    return null
  }

  const renderLeftSideButtons = () => {
    return(
      <LeftSideButtons>
        {/* <LogoutButton /> */}
        {renderLanguageSelector()}
        {renderResetButton()}
        {/*{renderLanguageSelector()}*/}
      </LeftSideButtons>
    )
  }

  return (
    <>
      <Container>
        <Wrapper>
          <ControlContainer>
            {/*{renderLeftSideButtons()}*/}
            {/*{renderBackButton()}*/}
          </ControlContainer>
          {children}
        </Wrapper>
        <Version />
      </Container>
    </>
  )
}

export default FormContainer

const ControlContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  top: 22px;
  outline: none;
  width: 100%;
  //max-width: 700px;
  z-index: 5;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  padding: 0 48px;
  align-items: flex-start;
`

const LeftSideButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const EmptyControl = styled.div`
`

const Container = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex:1;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  flex: 1;
  height: 100%;
  overflow: auto;
`
