export const SET_UI_THEME = 'SET_UI_THEME'
export const SET_LANGUAGE = 'SET_LANGUAGE'

export const HANDLE_SNACK_BAR = 'HANDLE_SNACK_BAR'
export const HANDLE_EXPIRED_SESSION_POPUP = 'HANDLE_EXPIRED_SESSION_POPUP'
export const HANDLE_OPT_EMAIL_MODAL = 'HANDLE_OPT_EMAIL_MODAL'
export const HANDLE_BUY_CRYPTO_MODAL = 'HANDLE_BUY_CRYPTO_MODAL'
export const HANDLE_PAYMENT_METHOD_SELECTION_MODAL = 'HANDLE_PAYMENT_METHOD_SELECTION_MODAL'
export const HANDLE_WALLET_INFO_MODAL = 'HANDLE_WALLET_INFO_MODAL'
export const HANDLE_OPT_SMS_MODAL = 'HANDLE_OPT_SMS_MODAL'
export const HANDLE_ROUTE_CHANGE = 'HANDLE_ROUTE_CHANGE'
export const HANDLE_IS_CURRENCY_LOADING = 'HANDLE_IS_CURRENCY_LOADING'
export const HANDLE_RELOAD_MODAL = 'HANDLE_RELOAD_MODAL'
export const HANDLE_YLOAD_MODAL = 'HANDLE_YLOAD_MODAL'
export const HANDLE_AGREE_PAYMENT_MODAL = 'HANDLE_AGREE_PAYMENT_MODAL'
