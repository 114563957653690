import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { getSession } from '../../../redux/actions/sessionActions'
import { RootState } from '../../../redux/reducers/rootReducer'
import { logEvent } from '../../../services/sentryService'

const ExpiredSessionPopup = () => {
  const { openedExpiredSessionPopup } = useSelector((state: RootState) => state.ui)
  const { session_guid } = useSelector((state: RootState) => state.session)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const refreshSession = async () => {
    setIsLoading(true)
    try {
      await dispatch(getSession(session_guid))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    logEvent('Session expired.')
  }, [])


  return (
    <div>
      <Dialog
        open={openedExpiredSessionPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { backgroundColor: '#fff' }}}
      >
        <DialogTitle style={{ color: '#000' }} id="alert-dialog-title">{'Your session has expired.'}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#000' }} id="alert-dialog-description">
            Click REFRESH SESSION to continue your payment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            onClick={refreshSession} color="primary"
          >
            REFRESH SESSION
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ExpiredSessionPopup
