import React from 'react'
import styled from 'styled-components'
import { ENV_MODE } from '../../constants'
import { getAppVersion } from '../../helpers/config'

const NEXT_PUBLIC_ENV_MODE = process.env.NEXT_PUBLIC_ENV_MODE

const Version = () => {
  return (
    <VersionContent>
      { NEXT_PUBLIC_ENV_MODE !== ENV_MODE.PRODUCTION ? getAppVersion() : '' }
    </VersionContent>
  )
}

export default Version

const VersionContent = styled.span`
position: absolute;
right: 10px;
bottom: 0;
font-size: 0.75rem;
`

