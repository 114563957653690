import { AnyAction, combineReducers } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ui from './ui'
import session from './session'
import transaction from './transaction'
import terminal from './terminal'
import payment from './payment'

const rootReducer = combineReducers({
  ui, session, transaction, terminal, payment,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default rootReducer
