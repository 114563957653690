import React from 'react'
import styled, { css } from 'styled-components'

const BackButton = (props) => {
  const { onClick = () => {}, buttonDisabled } = props

  const handleClick = async () => {
    if (!buttonDisabled) {
      await onClick()
    }
  }

  return (
    <Button
      disabled={buttonDisabled}
      onClick={handleClick}
      src={'/arrow-left.svg'}
      alt='back-button'
    />
  )
}

export default BackButton

const Button = styled.img`
  z-index: 5;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    left: 3%;
  }
  height: 24px;
  width: 24px;
  padding: 5px;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        &:hover {
          cursor: default;
        }
      `
    }
  }}
`
