import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { resetForm } from '../../redux/actions/sessionActions'
import { logEvent } from '../../services/sentryService'

const ResetButton = (props) => {
  const { disabled = false } = props

  const dispatch = useDispatch()

  const handleClick = async () => {
    if (!disabled) await reset()
  }

  const reset = async () => {
    logEvent('Pressed refresh button.')
    await dispatch(resetForm())
  }

  return(
    <Button onClick={() => handleClick()} src={'/reset.svg'} alt={'Reset'}/>
  )
}

export default ResetButton

const Button = styled.img`
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(217, 217, 217);
  align-self: flex-end;
  display: flex;
  :active {
    background-color: rgb(192 192 192);
    transform: translateY(2px); 
  }
`
