import { createGlobalStyle } from 'styled-components'


export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');

  body {
    margin: 0;
    color: ${({ theme }) => theme.palette.text.primary};
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    //min-height: 100vh;
    height: 100vh;
    height: 100svh;
    overflow: hidden;
  }
  
  body:before {
    //background: linear-gradient(138.96deg, #0FB2F8 -3.17%, #9F1265 112.15%);
    background: #F2F3F7;
    // background: ${({ theme }) => `linear-gradient(138.96deg, #0FB2F8 -3.17%, ${theme.palette.primary.main} 112.15%`};
    //filter: brightness(50%);
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
  }

  #__next {
  }
`
