import _ from 'lodash'

import dev from './dev'
import prod from './prod'
import extention from './extention'

const ENV_MODE = process.env.NEXT_PUBLIC_ENV_MODE || 'staging'

let config = _.merge(dev)

if (ENV_MODE === 'production') {
  config = _.merge(config, prod)
}

config = _.merge(config, extention)

export default config
