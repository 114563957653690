type Paths = {
  start(guid?: string): string
  disclosure(guid?: string): string
  email(): string
  enter_amount(): string
  enter_tip(): string
  read_card(): string
  enter_zip(): string
  verify_card(): string
  terminalManualForm(): string
  scan_documents(): string
  transaction_data(): string
  status(): string
  pp_verify_sms(): string
  pp_verify_card(): string
  amount_with_tips(): string
  phone(): string
  pp_info(): string
  forced_failed(): string
  crypto_payment(): string
  buy_crypto(): string
  wallet_address(): string
  wallet_enter_password(): string
  wallet_private_key(): string
  create_pre_transaction(): string
  transak_payment(): string
  banxa_payment(): string
  wallet_welcome(): string
  wallet_phone_number(): string
  wallet_verify_phone_number(): string
  wallet_deposit_offer(): string
  wallet_account(): string
  wallet_deposit_info(): string
  wallet_sva(): string
  yload_account(): string
  yload_init(): string
  gem_payment(): string
  algo_wallet(): string
  algo(): string
  nftpay_payment(): string
  quiltt_payment(): string
  uni_payment(): string
  combined_init(): string
}

type selectNavigator = {
  paths: Paths,
  step: string
}

type Step = {
  step: string
  sessionGuid: string
}

export function selectNavigator(state): selectNavigator {
  const sessionGuid = state.session.session_guid
  const sessionStateGuid = state.session.session_state_guid
  const reference = state.session.transaction_reference
  const formRoute = ''
  const initialRoute = `${formRoute}/${sessionGuid}/${sessionStateGuid}`

  return {
    paths: {
      start: (guid?) => {
        return guid ? `${formRoute}/${guid}` : initialRoute
      },
      disclosure: (guid?) => {
        const prefix = guid ? `${formRoute}/${guid}` : initialRoute
        return `${prefix}/disclosure`
      },
      phone: () => `${initialRoute}/phone`,
      email: () => `${initialRoute}/email`,
      enter_amount: () => `${initialRoute}/enter-amount`,
      enter_tip: () => `${initialRoute}/tip`,
      read_card: () => `${initialRoute}/read-card`,
      enter_zip: () => `${initialRoute}/enter-zip`,
      verify_card: () => `${initialRoute}/verify-data`,
      terminalManualForm: () => `${initialRoute}/terminal-manual-form`,
      scan_documents: () => `${initialRoute}/scan-document`,
      transaction_data: () => `${initialRoute}/data-form`,
      status: () => {
        return `${initialRoute}/status/${reference}`
      },
      pp_verify_sms: () => `${initialRoute}/sms-confirm`,
      pp_verify_card: () => `${initialRoute}/code-confirm`,
      amount_with_tips: () => `${initialRoute}/info`,
      pp_info: () => `${initialRoute}/pp-info`,
      create_pre_transaction: () => `${initialRoute}/pre-transaction`,
      forced_failed: () => `${initialRoute}/failed`,
      crypto_payment: () => `${initialRoute}/crypto-payment`,
      buy_crypto: () => `${initialRoute}/buy-crypto`,
      wallet_address: () => `${initialRoute}/wallet-address`,
      wallet_enter_password: () => `${initialRoute}/wallet-enter-password`,
      wallet_private_key: () => `${initialRoute}/wallet-private-key`,
      transak_payment: () => `${initialRoute}/payment`,
      banxa_payment: () => `${initialRoute}/payment`,
      wallet_account: () => `${initialRoute}/wallet-account`,
      wallet_welcome: () => `${initialRoute}/wallet-welcome`,
      wallet_phone_number: () => `${initialRoute}/wallet-phone-number`,
      wallet_verify_phone_number: () => `${initialRoute}/wallet-verify-phone-number`,
      wallet_deposit_offer: () => `${initialRoute}/wallet-deposit-offer`,
      wallet_deposit_info: () => `${initialRoute}/wallet-deposit-info`,
      wallet_sva: () => `${initialRoute}/wallet-sva`,
      yload_account: () => `${initialRoute}/yload-account`,
      yload_init: () => `${initialRoute}/yload-init`,
      gem_payment: () => `${initialRoute}/gem-payment`,
      algo_wallet: () => `${initialRoute}/algo-wallet`,
      algo: () => `${initialRoute}/algo`,
      nftpay_payment: () => `${initialRoute}/payment`,
      quiltt_payment: () => `${initialRoute}/connector`,
      uni_payment: () => `${initialRoute}/payment`,
      combined_init: () => `${initialRoute}/combined-init`,
    },
    step: state.session.step,
  }
}
