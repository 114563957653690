import {
  SET_DEVICE_FEATURES,
  SET_PRINTER_STATUS,
  SET_RAW_CARD_NUMBER,
} from '../actionTypes/terminal'
import { PRINTER_STATUSES } from '../../constants/transaction'

interface TerminalInitialState {
  deviceFeatures: {
    scanner: boolean
    magneticStripe: boolean
  },
  printerStatus: PRINTER_STATUSES,
  rawData: {
    cardNumber: string,
  }
}

const initialState: TerminalInitialState = {
  deviceFeatures: {
    scanner: false,
    magneticStripe: false,
  },
  printerStatus: null,
  rawData: {
    cardNumber: '',
  },
}

export default function terminal(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICE_FEATURES:
      return {
        ...state,
        deviceFeatures: {
          ...action.payload,
        },
      }
    case SET_PRINTER_STATUS:
      return {
        ...state,
        printerStatus: action.payload,
      }
    case SET_RAW_CARD_NUMBER: {
      return {
        ...state,
        rawData: {
          ...state.rawData,
          cardNumber: action.payload,
        },
      }
    }
    default:
      return { ...state }
  }
}
