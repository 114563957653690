import {
  SET_UI_THEME,
  SET_LANGUAGE,
  HANDLE_IS_CURRENCY_LOADING,
  HANDLE_SNACK_BAR,
  HANDLE_OPT_SMS_MODAL,
  HANDLE_OPT_EMAIL_MODAL,
  HANDLE_EXPIRED_SESSION_POPUP,
  HANDLE_ROUTE_CHANGE,
  HANDLE_BUY_CRYPTO_MODAL,
  HANDLE_WALLET_INFO_MODAL,
  HANDLE_PAYMENT_METHOD_SELECTION_MODAL,
  HANDLE_RELOAD_MODAL,
  HANDLE_YLOAD_MODAL, HANDLE_AGREE_PAYMENT_MODAL,
} from '../actionTypes/ui'

export const updateUITheme = (tenant) => async (dispatch, getState) => {
  try {
    const currentTheme = getState().ui.ui_theme
    const updatedTenantTheme = { ...currentTheme }

    const primaryColor = '#C80F7C'
    const secondaryColor = '#1C8FBD'
    // const primaryColor = tenant?.primary_color || '#C80F7C'
    // const secondaryColor = tenant?.secondary_color || '#1C8FBD'

    updatedTenantTheme.palette.primary.main = primaryColor
    updatedTenantTheme.palette.primary.hover = `${primaryColor}99`
    
    updatedTenantTheme.palette.info.main = secondaryColor
    updatedTenantTheme.palette.info.hover = `${secondaryColor}99`
    
    await dispatch(setUiTheme(updatedTenantTheme))
  } catch (err) {
    console.log('error from getTenantConfig', err)
  }
}

export const setUiTheme = payload => {
  return {
    type: SET_UI_THEME,
    payload,
  }
}

export const setLanguage = payload => {
  return {
    type: SET_LANGUAGE,
    payload,
  }
}

export const manageSnackBar = payload => {
  return {
    type: HANDLE_SNACK_BAR,
    payload: {
      message: payload.message,
      opened: payload.opened,
      severity: payload.severity || 'error',
      duration: payload.duration || 3000,
    },
  }
}

export const handleOTPSMSModal = payload => {
  return {
    type: HANDLE_OPT_SMS_MODAL,
    payload,
  }
}

export const handleOTPEmailModal = payload => {
  return {
    type: HANDLE_OPT_EMAIL_MODAL,
    payload,
  }
}

export const handleBuyCryptoModal = payload => {
  return {
    type: HANDLE_BUY_CRYPTO_MODAL,
    payload,
  }
}

export const handlePaymentMethodSelectionModal = payload => {
  return {
    type: HANDLE_PAYMENT_METHOD_SELECTION_MODAL,
    payload,
  }
}

export const handleWalletInfoModal = payload => {
  return {
    type: HANDLE_WALLET_INFO_MODAL,
    payload,
  }
}

export const manageExpiredSessionPopup = (isOpen: boolean) => {
  return {
    type: HANDLE_EXPIRED_SESSION_POPUP,
    payload: isOpen,
  }
}

export const handleRouteChange = payload => {
  return {
    type: HANDLE_ROUTE_CHANGE,
    payload,
  }
}

export const handleCurrencyLoading = payload => {
  return {
    type: HANDLE_IS_CURRENCY_LOADING,
    payload,
  }
}

export const handleReloadModal = payload => {
  return {
    type: HANDLE_RELOAD_MODAL,
    payload,
  }
}

export const handleYloadModal = payload => {
  return {
    type: HANDLE_YLOAD_MODAL,
    payload,
  }
}

export const handleAgreePaymentModal = payload => {
  return {
    type: HANDLE_AGREE_PAYMENT_MODAL,
    payload,
  }
}
