import { axiosInstance } from './index'
import apiUrls from './apiUrls'
import { applicationAxiosInstance } from './axiosInstance'
import { axiosRetry, returnAxiosError } from 'helpers'
import { NETWORK_ERROR_TEXT } from 'constants/error'

type OrderSession = {
  order_session_id: number
  guid: string
  order_id: string
  amount: number
  tip: number
  transaction_mode: string
  full_customer_session: boolean
  merchant_id: number
  location_id: number
  agent_id: number
  redirect_url: string
  device_type: string
  customer_data: any
  x_data: any
  created_at: string
  updated_at: string
}

export const keepOrderSession = async (guid): Promise<{ active: boolean, is_uniq: boolean }> => {
  const { data } = await axiosInstance.post(`${apiUrls.keepOrderSession}/${guid}`)
  return data
}

export const startApp = async (sessionGuid: string, sessionStateGuid?: string): Promise<any> => {
  const requestData: any = { order_session_guid: sessionGuid }
  if (sessionStateGuid) requestData.order_session_state_guid = sessionStateGuid
  const token = localStorage.getItem('auth_token')

  let header = null

  if (token) {
    header = {
      'authorization': `${token}`,
    }
  }

  const { data } = await applicationAxiosInstance.post(`${apiUrls.startApp}`, requestData, { headers: header })
  return data
}

export const goNext = async (sessionStateGuid, sessionGuid, sessionState?, predicatedStep?, customHeaders?) => {
  const requestData: any = {}
  if (sessionState) requestData.data = sessionState
  if (predicatedStep) requestData.predicated_step = predicatedStep
  let data = null
  const token = localStorage.getItem('auth_token')
  const refresh = localStorage.getItem('refresh_token')

  let headers = null

  if (token && refresh) {
    headers = {
      'authorization': `${token}`,
    }
  }


  try {
    const result = await applicationAxiosInstance.post(`${apiUrls.goNext}/${sessionStateGuid}`, requestData, { headers: customHeaders ? customHeaders : headers })
    data = result.data
  } catch (e) {

    if (e?.message === NETWORK_ERROR_TEXT) throw e
    data = axiosRetry(() => startApp(sessionGuid, sessionStateGuid), 5000, 5)

    if (e.response?.data?.errors.find(e => e.error_code === 'ORDER_SESSION_AUTH_TOKEN_REQUIRED')) {
      throw e
    }

    if (e.response?.data?.errors.find(e => e.error_code ==='ORDER_SESSION_AUTH_TOKEN_INVALID')) {
      const tokens = await refreshToken(refresh)
      console.log('tokens', tokens)
      data = axiosRetry(() => goNext(sessionStateGuid, sessionGuid, sessionState, predicatedStep, {
        'authorization': `${tokens.auth_token}`,
      }), 0, 1)
    }
  }
  return data
}

export const refreshToken = async (refresh_token) => {
  try {
    const result = await applicationAxiosInstance.post(`${apiUrls.refreshToken}`, {refresh_token})
    if (result.data?.auth_token && result.data?.refresh_token) {
      localStorage.setItem('auth_token', result.data?.auth_token)
      localStorage.setItem('refresh_token', result.data?.refresh_token)
    }

    return result.data
  } catch (e) {
    if (e.response?.status_code === 401) {
      localStorage.removeItem('auth_token')
      localStorage.removeItem('refresh_token')
    }
  }
}

export const logout = async (sessionStateGuid: string) => {
  const result = await applicationAxiosInstance.post(`${apiUrls.logout}/${sessionStateGuid}`)
  return result.data
}


export const getTransactions = async (limit, skip?) => {
  const token = localStorage.getItem('auth_token')
  let header = null

  if (token) {
    header = {
      'authorization': `${token}`,
    }
  }

  const param = {
    limit: limit || null,
    skip: skip || null,
  }

  try {
    const result = await applicationAxiosInstance.post(`${apiUrls.getTransactionsList}`, null, { headers: header, params: param } )
    return result?.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const approveOrder = async (reference: string) => {
  const token = localStorage.getItem('auth_token')
  let header = null

  if (token) {
    header = {
      'authorization': `${token}`,
    }
  }

  try {
    const { data } = await applicationAxiosInstance.post(`${apiUrls.approveOrder}`, { reference }, { headers: header })
    return {
      redirect_url: data?.redirect_url,
      virtual_card_number: data?.virtual_card_number,
    }
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const rejectOrder = async (reference: string) => {
  const token = localStorage.getItem('auth_token')
  let header = null

  if (token) {
    header = {
      'authorization': `${token}`,
    }
  }

  try {
    const { data } = await applicationAxiosInstance.post(`${apiUrls.rejectOrder}`, { reference }, { headers: header })
    return data?.redirect_url
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const getUserBalance = async () => {

  const token = localStorage.getItem('auth_token')
  let header = null

  if (token) {
    header = {
      'authorization': `${token}`,
    }
  }

  try {
    const result = await applicationAxiosInstance.post(`${apiUrls.getUserBalance}`, null, { headers: header })
    return result?.data
  } catch (e) {
    throw Error(returnAxiosError(e))
  }
}

export const goBack = async (sessionStateGuid, sessionGuid, customHeaders?) => {
  let data = null
  const token = localStorage.getItem('auth_token')
  const refresh = localStorage.getItem('refresh_token')

  let headers = null

  if (token && refresh) {
    headers = {
      'authorization': `${token}`,
    }
  }

  try {
    const result = await applicationAxiosInstance.post(`${apiUrls.goBack}/${sessionStateGuid}`, null, { headers: customHeaders ? customHeaders : headers })
    data = result.data
  } catch (e) {
    if (e?.message === NETWORK_ERROR_TEXT) {
      data = axiosRetry(() => startApp(sessionGuid, sessionStateGuid), 5000, 5)
    }

    if (e.response?.data?.errors.find(e => e.error_code ==='ORDER_SESSION_AUTH_TOKEN_INVALID')) {
      const tokens = await refreshToken(refresh)
      data = axiosRetry(() => goBack(sessionStateGuid, sessionGuid, {
        'authorization': `${tokens.auth_token}`,
      }), 0, 1)
    }

    throw e
  }
  return data
}

